import styled from 'styled-components';
import PrimaryActionButton from '../../sidepanel/PrimaryActionButton';
import DragAndDrop from '../../common/DragAndDrop';
import type {
  ArtifactFile,
  ArtifactUpload,
  StoryVideoMusicStrategy,
} from './useStoryCreator';
import BRollUploading from './BRollUploading';
import { OptionList, OptionItem } from '../../common/OptionList';
import ItemDropdownSelect from '@src/components/common/ItemDropdownSelect';
import { ORIGINAL_VIDEO_LANGUAGE_OPTIONS } from '@src/config/languages';
import { useEffect } from 'react';

type StoryTypeOption = {
  value: 'produced' | 'raw';
  text: string;
};

const STORY_TYPE_OPTIONS: StoryTypeOption[] = [
  {
    value: 'produced',
    text: 'I have produced content',
  },
  {
    value: 'raw',
    text: 'I have raw content',
  },
];

type StoryVideoMusicStrategyOption = {
  value: StoryVideoMusicStrategy;
  text: string;
};

const STORY_VIDEO_MUSIC_STRATEGY_OPTIONS_PRODUCED: StoryVideoMusicStrategyOption[] =
  [
    {
      value: 'keep',
      text: 'Keep my music',
    },
    {
      value: 'process',
      text: 'Remove music',
    },
  ];

const STORY_VIDEO_MUSIC_STRATEGY_OPTIONS_RAW: StoryVideoMusicStrategyOption[] =
  [
    {
      value: 'process',
      text: 'Correct audio',
    },
    {
      value: 'keep',
      text: 'Leave audio',
    },
  ];

const MUSIC_OPTIONS_TITLE: Record<'raw' | 'produced', string> = {
  produced: 'Music',
  raw: 'Audio',
};

const MUSIC_OPTIONS_LIST: Record<
  'raw' | 'produced',
  StoryVideoMusicStrategyOption[]
> = {
  produced: STORY_VIDEO_MUSIC_STRATEGY_OPTIONS_PRODUCED,
  raw: STORY_VIDEO_MUSIC_STRATEGY_OPTIONS_RAW,
};

const Title = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  text-align: center;
  color: #f3e9d7;
  margin: 0;

  margin-bottom: 24px;
`;

const ProceedButton = styled(PrimaryActionButton)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 24px;
`;

const UploadArea = styled.div`
  margin-bottom: 16px;
`;

const Hint = styled.span`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FieldSet = styled.div`
  margin: 0 0 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #484848;
  padding: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: #03041a;
`;

const DropdownWrapper = styled.div`
  color: #f3e9d7;
  .Selected {
    padding: 16px;
  }
`;

export function StepOne({
  goToNextStep,
  canGoToStep,
  handleStoryVideoUpload,
  maxUploadSizeGB,
}: {
  goToNextStep: () => void;
  canGoToStep: boolean;
  handleStoryVideoUpload: (f: File[]) => void;
  maxUploadSizeGB: number;
}) {
  return (
    <>
      <Title>Upload your video</Title>
      <UploadArea>
        <DragAndDrop
          width={440}
          callback={handleStoryVideoUpload}
          acceptType="video"
          multiple={false}
          description={
            <Hint>
              <span>Or upload (or drag and drop) your video here.</span>
              <span style={{ color: '#EF5D6F' }}>
                {`Max file size is ${maxUploadSizeGB} GB and upload MP4s only`}
              </span>
            </Hint>
          }
        />
      </UploadArea>
      <ProceedButton onClick={goToNextStep} disabled={!canGoToStep} isActivated>
        Next
      </ProceedButton>
    </>
  );
}

export function StepTwo({
  goToNextStep,
  canGoToStep,
  storyType,
  setStoryType,
  videoMusicStrategy,
  setVideoMusicStrategy,
}: {
  goToNextStep: () => void;
  canGoToStep: boolean;
  storyType: 'raw' | 'produced' | null;
  setStoryType: (t: 'raw' | 'produced') => void;
  videoMusicStrategy: StoryVideoMusicStrategy;
  setVideoMusicStrategy: (s: StoryVideoMusicStrategy) => void;
}) {
  return (
    <>
      <Title>Story Details</Title>
      <FieldSet>
        <FieldName>Story Type</FieldName>
        <OptionList>
          {STORY_TYPE_OPTIONS.map((o) => (
            <OptionItem
              isCurrent={o.value === storyType}
              onClick={() => setStoryType(o.value)}
              key={o.value}
            >
              {o.text}
            </OptionItem>
          ))}
        </OptionList>
      </FieldSet>
      {storyType && (
        <FieldSet>
          <FieldName>{MUSIC_OPTIONS_TITLE[storyType]}</FieldName>
          <OptionList>
            {MUSIC_OPTIONS_LIST[storyType].map((o) => (
              <OptionItem
                isCurrent={o.value === videoMusicStrategy}
                onClick={() => setVideoMusicStrategy(o.value)}
                key={o.value}
              >
                {o.text}
              </OptionItem>
            ))}
          </OptionList>
        </FieldSet>
      )}
      <ProceedButton onClick={goToNextStep} disabled={!canGoToStep} isActivated>
        Next
      </ProceedButton>
    </>
  );
}

export function StepThree({
  goToNextStep,
  canGoToStep,
  storyName,
  setStoryName,
  storyLanguage,
  setStoryLanguage,
  allowLangSelect,
}: {
  goToNextStep: () => void;
  canGoToStep: boolean;
  storyName: string;
  setStoryName: (s: string) => void;
  storyLanguage: string | undefined;
  setStoryLanguage: (s: string) => void;
  allowLangSelect?: boolean;
}) {
  useEffect(() => {
    // so that page scrolling doesn't interfere with dropdown scrolling
    const overlow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = overlow;
    };
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (canGoToStep) {
          goToNextStep();
        }
      }}
    >
      <Title>Story Details</Title>
      <FieldSet>
        <FieldName>Story Name</FieldName>
        <Input
          required
          placeholder="Name your story"
          value={storyName}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => setStoryName(e.target.value)}
        />
      </FieldSet>
      {allowLangSelect && (
        <FieldSet
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <FieldName>Transcript Language</FieldName>
          <DropdownWrapper>
            <ItemDropdownSelect
              action={(_, value) => value && setStoryLanguage(value)}
              propertyName="storyLanguage"
              defaultValue={storyLanguage}
              values={ORIGINAL_VIDEO_LANGUAGE_OPTIONS}
            />
          </DropdownWrapper>
        </FieldSet>
      )}
      <ProceedButton isActivated disabled={!canGoToStep} type="submit">
        Next
      </ProceedButton>
    </form>
  );
}

export function StepFour({
  goToNextStep,
  handleStoryBRollUpload,
  storyArtifactsFiles,
  storyArtifacts,
  storyArtifactsVideoFiles,
  storyArtifactsVideo,
  saveArtifactNotes,
  setArtifactNotes,
  deleteArtifact,
  saveArtifactVideoNotes,
  setArtifactVideoNotes,
  deleteArtifactVideo,
}: {
  goToNextStep: () => void;
  handleStoryBRollUpload: (f: File[]) => void;
  storyArtifactsFiles: ArtifactFile[];
  storyArtifactsVideoFiles: ArtifactFile[];
  storyArtifacts: ArtifactUpload[];
  storyArtifactsVideo: ArtifactUpload[];
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
  deleteArtifact: (internalId: string) => void;
  saveArtifactVideoNotes: (internalId: string) => void;
  setArtifactVideoNotes: (internalId: string, notes: string) => void;
  deleteArtifactVideo: (internalId: string) => void;
}) {
  return (
    <>
      <Title>Lets collect BRoll</Title>
      {storyArtifactsFiles.length === 0 &&
      storyArtifactsVideoFiles.length === 0 ? (
        <UploadArea>
          <DragAndDrop
            width={440}
            callback={handleStoryBRollUpload}
            acceptType="all"
            multiple
            description={
              <Hint>
                <span>
                  Or upload (or drag and drop) your videos and photos here.
                </span>
                <span style={{ color: '#EF5D6F' }}>
                  (Max file size is 100 MB per file)
                </span>
              </Hint>
            }
          />
        </UploadArea>
      ) : (
        <BRollUploading
          handleStoryBRollUpload={handleStoryBRollUpload}
          storyArtifactsFiles={storyArtifactsFiles}
          storyArtifactsVideoFiles={storyArtifactsVideoFiles}
          storyArtifacts={storyArtifacts}
          storyArtifactsVideo={storyArtifactsVideo}
          saveArtifactNotes={saveArtifactNotes}
          setArtifactNotes={setArtifactNotes}
          deleteArtifact={deleteArtifact}
          saveArtifactVideoNotes={saveArtifactVideoNotes}
          setArtifactVideoNotes={setArtifactVideoNotes}
          deleteArtifactVideo={deleteArtifactVideo}
        />
      )}
      <ProceedButton onClick={goToNextStep} isActivated>
        Next
      </ProceedButton>
      <BRollUploadsHint>Advancing will not lose your uploads</BRollUploadsHint>
    </>
  );
}

const BRollUploadsHint = styled.p`
  margin-top: 16px;
  color: #848484;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
`;
