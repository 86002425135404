import styled, { CSSProperties, css } from 'styled-components';
import { SharedContent, SocialPost } from '../../types.ts/story';

import SharesIcon from '../../svgs/SharesIcon';
import ViewsIcon from '../../svgs/ViewsIcon';
import LikesIcon from '../../svgs/LikesIcon';
import CommentsIcon from '../../svgs/CommentsIcon';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { get, set } from 'lodash';
import { getStats } from 'unsplash-js/dist/methods/photos';
import AnalyticsDetailsDropdown from '../common/AnalyticsDetailsDropdown';
import { delay, formatAnalyticsNumber } from '../../utility/general';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Layout = 'wide' | 'share-modal' | 'studio-main' | 'dashboard';
type Key = 'views' | 'likes' | 'shares' | 'comments';

type Props = {
  type: 'story' | 'video' | 'shareableContent' | undefined;
  id: string | undefined;
  platform?: string;
  allReferencingSharedContents?: SharedContent[] | undefined;
  layout?: Layout;
};

const SocialStats = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { type, id, platform, allReferencingSharedContents, layout } = props;
  const [prevStoryId, setPrevStoryId] = useState<string | undefined>();

  const initValue = (key: Key) => {
    return (
      allReferencingSharedContents?.reduce(
        (sum: number, sc: SharedContent) => sum + sc[key],
        0,
      ) || 0
    );
  };
  const [views, setViews] = useState<number>(initValue('views'));
  const [shares, setShares] = useState<number>(initValue('shares'));
  const [likes, setLikes] = useState<number>(initValue('likes'));
  const [comments, setComments] = useState<number>(initValue('comments'));
  const [sharedContents, setSharedContents] = useState<
    SharedContent[] | undefined
  >(allReferencingSharedContents);
  const [showStats, setShowStats] = useState(
    allReferencingSharedContents && allReferencingSharedContents?.length > 0,
  );
  const [hoverItem, setHoverItem] = useState<Key | null>(null);
  const [isWaiting, setisWaiting] = useState(true);
  // if (type === 'story') {
  //   console.log('inits', showStats, id, platform, views, shares, likes, comments);
  //   console.log('calc', showStats, id, platform, initValue('views'), initValue('shares'), initValue('likes'), initValue('comments'));
  // }

  useEffect(() => {
    if (prevStoryId !== id) setPrevStoryId(id);
  }, [prevStoryId, id]);

  useEffect(() => {
    if (!isWaiting || layout !== 'studio-main') return;
    (async () => {
      await delay(2000);
      setisWaiting(false);
    })();
  }, [isWaiting]);

  useEffect(() => {
    async function getStats(
      type: string | undefined,
      id: string | undefined,
      allReferencingSharedContents: SharedContent[] | undefined,
      platform: string | undefined,
    ) {
      // if sharedContentStats haven't been updated in last 30 minutes, go fetch
      if (layout === 'studio-main') console.log('id', id, 'type', type);
      if (
        type &&
        id &&
        allReferencingSharedContents &&
        allReferencingSharedContents?.length > 0
      ) {
        const resp = await fetch(
          `${process.env.REACT_APP_API_URL}/api/sharing/analytics`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: type,
              id: id,
              platform: platform,
            }),
          },
        );

        const json = await resp.json();

        if (json.error) {
          console.log(json.error);

          return;
        } else {
          // if (type === 'story') {
          //   console.log('got from server', json)
          // }
          if (
            (layout === 'studio-main' &&
              json.sharedContents[0]?.story?.id === videoCreator.storyId) ||
            layout !== 'studio-main'
          ) {
            setViews(json.views || 0);
            setShares(json.shares || 0);
            setLikes(json.likes || 0);
            setComments(json.comments || 0);
            setSharedContents(json.sharedContents);
          }
        }
      }
    }

    if (layout === 'studio-main' && prevStoryId !== id) {
      setViews(0);
      setShares(0);
      setLikes(0);
      setComments(0);
      return;
    }

    // init stats never go less than the values from server

    setViews(initValue('views'));
    setShares(initValue('shares'));
    setLikes(initValue('likes'));
    setComments(initValue('comments'));

    setSharedContents(allReferencingSharedContents);
    setShowStats(
      allReferencingSharedContents && allReferencingSharedContents?.length > 0,
    );
    getStats(type, id, allReferencingSharedContents, platform);
    // setPrevStoryId(id)
  }, [type, id, allReferencingSharedContents, prevStoryId]);

  // function that will give two significant figures and K for thousands and M for millions to numbers

  const showStatText = layout !== 'wide' && layout !== 'dashboard';
  const iconWidth = layout == 'dashboard' ? '14px' : undefined;
  const iconHeight = layout == 'dashboard' ? '14px' : undefined;

  const renderStat = (key: Key, value: number, Icon: React.ReactNode) => {
    return (
      <Stat key={key} layout={layout} iconType={key}>
        <Layer
          layout={layout}
          statValue={value}
          onMouseEnter={() => {
            if (
              value &&
              value.toString() !== '...' &&
              layout === 'studio-main'
            ) {
              setHoverItem(key);
            }
          }}
          onMouseLeave={() => setHoverItem(null)}
        >
          <StatItem layout={layout}>
            <StatIcon>{Icon}</StatIcon>
            {showStatText && <StatTitle>{key}</StatTitle>}
          </StatItem>
          <StatNumber layout={layout} isDisabled={!showStats}>
            {formatAnalyticsNumber(value)}
          </StatNumber>
          {hoverItem === key && (
            <AnalyticsDetailsDropdown
              type={key}
              Icon={Icon}
              allReferencingSharedContents={sharedContents}
            />
          )}
        </Layer>
      </Stat>
    );
  };

  const getAnalyticsValue = (value: number) => {
    if (layout !== 'studio-main') return value;
    if (isWaiting) return '...';
    return value;
  };

  const data = [
    {
      key: 'views',
      value: getAnalyticsValue(views),
      Icon: <ViewsIcon width={iconWidth} height={iconHeight} />,
    },
    {
      key: 'likes',
      value: getAnalyticsValue(likes),
      Icon: <LikesIcon width={iconWidth} height={iconHeight} />,
    },
    {
      key: 'shares',
      value: getAnalyticsValue(shares),
      Icon: <SharesIcon width={iconWidth} height={iconHeight} />,
    },
    {
      key: 'comments',
      value: getAnalyticsValue(comments),
      Icon: <CommentsIcon width={iconWidth} height={iconHeight} />,
    },
  ] as { key: Key; value: number; Icon: React.ReactNode }[];

  return (
    <StatsWrapper layout={layout}>
      {data.map(({ key, value, Icon }) => renderStat(key, value, Icon))}
    </StatsWrapper>
  );
});

export default SocialStats;

const StatsWrapper = styled.div<{ layout?: Layout }>`
  display: flex;
  gap: 8px;
  margin: 10px 0;

  ${({ layout }) =>
    layout === 'wide' &&
    css`
      justify-content: space-between;
      width: 95%;
    `}

  ${({ layout }) =>
    layout === 'dashboard' &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    `}
`;

const Stat = styled.div<{ layout?: Layout; iconType: string }>`
  display: flex;
  flex-direction: ${({ layout }) =>
    layout === 'wide' || layout === 'dashboard' ? 'row' : 'column'};

  ${({ layout }) =>
    layout === 'share-modal' &&
    css`
      width: 24%;
      height: 96px;
    `}

  ${({ layout, iconType }) =>
    layout === 'studio-main' &&
    css`
      width: 136px;
      height: 96px;

      align-items: flex-start;
      padding-left: 10px;
      text-align: center;
    `}
`;

const Layer = styled.div<{ layout?: Layout; statValue: number }>`
  display: flex;
  flex-direction: ${({ layout }) => (layout === 'wide' ? 'row' : 'column')};
  height: 100%;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;

  ${(props) =>
    props.layout === 'studio-main' &&
    css`
      .stat-hover-trigger:hover ~ .dropdown,
      .dropdown:hover {
        display: ${props.statValue ? 'flex' : 'none'};
      }
    `}

  ${({ layout }) =>
    layout === 'studio-main' &&
    css`
      text-align: center;
    `}

  ${({ layout }) =>
    layout === 'wide' &&
    css`
      flex-direction: row;
    `}

  ${({ layout }) =>
    layout === 'dashboard' &&
    css`
      flex-direction: row-reverse;
      row-gap: 2px;
      column-gap: 4px;
      justify-content: space-between;
      max-width: 100px;
      width: 100%;
    `}

  ${({ layout }) =>
    layout !== 'wide' &&
    layout !== 'dashboard' &&
    css`
      flex-direction: column;
    `}
`;

const StatIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f2d093;
`;

const StatTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 12.1px;
  color: #bdbdbd;
  text-transform: capitalize;
`;

const StatItem = styled.div<{ layout?: Layout }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  ${(props) =>
    props.layout === 'studio-main' &&
    css`
      padding-bottom: 5px;
      margin-bottom: -5px;
    `}
`;

const StatNumber = styled.div<{ isDisabled?: boolean; layout?: Layout }>`
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  color: #f3e9d7;

  ${({ layout }) =>
    layout === 'studio-main' &&
    css`
      font-size: 32px;
    `}

  ${({ layout }) =>
    layout === 'dashboard' &&
    css`
      font-size: 16px;
    `}
`;

const StatLabel = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
`;
