import React, { useState, useRef, useEffect, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { uniqBy } from 'lodash';

import {
  FONTS,
  checkWordsInString,
  scrollIfNotInView,
} from '../../utility/general';
import { SearchIconAlt } from '../../svgs/SearchIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  action: (propertyName: string, value: string | null) => void;
  propertyName: string;
  defaultValue: string | undefined | null;
  type?: 'karaoke' | 'basic';
  fontWeight?: string;
  hasStickyBottom?: boolean;
  className?: string;
};

type FontItem = { label: string } | { name: string; postScriptName: string };

const ArrowDownIcon = (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path d="M2 4.33008L6 8.33008L10 4.33008" stroke="#F2D093" />
  </svg>
);

const FontDropdownSelect = observer(
  forwardRef((props: Props, ref) => {
    const videoCreator = useVideoCreatorStore();
    const brandFonts = videoCreator.organization?.branding?.brandFonts || [];
    const brandFontNames = brandFonts.map((f) => f.fontFamily);
    const brandCustomFonts =
      videoCreator.organization?.branding?.customFonts?.filter(
        (f) => f.style !== 'italic' && !f.archivedAt,
      ) || [];
    let allFonts: FontItem[] = FONTS.map((f) => ({
      name: f,
      postScriptName: f,
    }));
    if (brandFonts.length) {
      allFonts = [
        { label: 'Brand Fonts' },
        ...brandFonts.map((f) => ({
          name: f.fontFamily as string,
          postScriptName: (f.postScriptName || f.fontFamily) as string,
        })),
      ];

      let customFonts: FontItem[] = uniqBy(
        brandCustomFonts
          .filter((f) => !brandFontNames.includes(f.fontFamily))
          .map((f) => ({
            name: f.fontFamily,
            postScriptName: f.postScriptName,
          })),
        'postScriptName',
      );
      if (customFonts.length) {
        allFonts = [...allFonts, { label: 'Custom Fonts' }, ...customFonts];
      }

      const otherFonts: FontItem[] = FONTS.filter(
        (f) => !brandFontNames.includes(f),
      ).map((f) => ({ name: f, postScriptName: f }));
      allFonts = [...allFonts, { label: 'Popular Fonts' }, ...otherFonts];
      // dedupe fonts
      allFonts = allFonts.reduce((acc, current) => {
        const x = acc.find((item: FontItem) =>
          'label' in item || 'label' in current
            ? false
            : item.name === current.name,
        );
        if (!x) return acc.concat([current]);
        else return acc;
      }, [] as FontItem[]);
    }

    const [fonts, setFonts] = useState<any[]>(allFonts);
    const selectedFontFamily =
      brandCustomFonts.find((f) => f.postScriptName === props.defaultValue)
        ?.fontFamily || props.defaultValue;
    const [selectedFont, setSelectedFont] = useState<string | undefined | null>(
      selectedFontFamily,
    );
    const [showDropdown, toggleDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(
      null,
    );

    useEffect(() => {
      if (showDropdown && dropdownRef.current && ref) {
        const container = (ref as React.RefObject<HTMLDivElement>)
          .current as Element;

        const stickyContainerHeight = props.hasStickyBottom ? 100 : 0;

        scrollIfNotInView(
          dropdownRef.current,
          container,
          stickyContainerHeight,
        );
      }
    }, [showDropdown]);

    const contentRef = useRef<HTMLDivElement>(null);

    useOutsideAlerter(contentRef, () => {
      toggleDropdown(false);
      setHighlightedIndex(null);
    });

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
      const searchWords = e.target.value;
      setSelectedFont(searchWords);
      const fonts = allFonts.filter(
        (font) =>
          !('label' in font) &&
          font.name &&
          checkWordsInString(font.name, searchWords),
      );
      toggleDropdown(true);
      setFonts(fonts);
    }

    async function handleSelectFont(value: string) {
      setSelectedFont(value);
      props.action(props.propertyName, value);
      toggleDropdown(false);
      setHighlightedIndex(null);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        const value = (e.target as HTMLInputElement).value;
        const exists = fonts.some((f) => f.name === value);

        if (exists || (fonts.length === 1 && value)) {
          const selectedValue = fonts.length === 1 ? fonts[0].name : value;
          handleSelectFont(selectedValue);
        } else if (highlightedIndex !== null) {
          const font = fonts[highlightedIndex];
          handleSelectFont(font.name);
        }
      } else if (e.code === 'ArrowDown') {
        e.preventDefault();
        const scrollIndex =
          highlightedIndex === null ? 0 : highlightedIndex + 1;
        const nextIndex = Math.min(scrollIndex, fonts.length - 1);
        setHighlightedIndex(nextIndex);
        scrollToHighlightedItem(nextIndex);
      } else if (e.code === 'ArrowUp') {
        e.preventDefault();

        const scrollIndex =
          highlightedIndex === null ? 0 : highlightedIndex - 1;
        const prevIndex = Math.max(scrollIndex, 0);
        setHighlightedIndex(prevIndex);
        scrollToHighlightedItem(prevIndex);
      }
    };

    const scrollToHighlightedItem = (index: number) => {
      if (dropdownRef.current) {
        const item = dropdownRef.current.children[index] as HTMLElement; // Type assertion here
        if (item) {
          const container = dropdownRef.current;
          const itemTop = item.offsetTop;
          const itemBottom = itemTop + item.clientHeight;

          // Check if item needs to be scrolled into view
          if (itemTop < container.scrollTop) {
            container.scrollTop = itemTop; // Scroll up if the item is above the visible area
          } else if (
            itemBottom >
            container.scrollTop + container.clientHeight
          ) {
            container.scrollTop = itemBottom - container.clientHeight; // Scroll down if the item is below the visible area
          }
        }
      }
    };

    const renderDropdownItem = (item: FontItem, idx: number) => {
      if ('label' in item) {
        return <DropdownLabel key={item.label}>{item.label}</DropdownLabel>;
      } else {
        return (
          <DropdownItem
            isSelected={idx === highlightedIndex}
            item={item.name}
            key={item.postScriptName}
            onClick={() => handleSelectFont(item.postScriptName)}
          >
            {item.name}
          </DropdownItem>
        );
      }
    };

    return (
      <Main ref={contentRef} className={props.className}>
        {props.type === 'basic' && <FieldName>Style</FieldName>}
        <InputContainer
          onClick={() => {
            toggleDropdown(true);
            setSelectedFont('');
            setFonts(allFonts);
          }}
          onKeyDown={handleKeyDown}
        >
          <InputContent
            placeholder="Search"
            selectedFont={props.defaultValue}
            fontWeight={props.fontWeight}
            value={selectedFontFamily || ''}
            onChange={handleSearch}
          />
          {
            <ToggleIcon>
              {showDropdown ? <SearchIconAlt /> : ArrowDownIcon}
            </ToggleIcon>
          }
        </InputContainer>

        {showDropdown && Boolean(fonts.length) && (
          <Dropdown ref={dropdownRef}>
            {fonts.map((item, idx) => renderDropdownItem(item, idx))}
          </Dropdown>
        )}
      </Main>
    );
  }),
);

export default FontDropdownSelect;

const Main = styled.div`
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  flex: 1;
`;

const InputContainer = styled.div`
  border: 1px solid #484848;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  cursor: pointer;

  padding: 0px 16px;
  padding-left: 0;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const InputContent = styled.input<{
  selectedFont?: string | null;
  fontWeight?: string;
}>`
  font-size: 14px;
  ${(props) =>
    props.selectedFont &&
    css`
      font-family: ${props.selectedFont}, Inter;
      ${props.fontWeight && `font-weight: ${props.fontWeight};`}
    `}
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #f3e9d7;
  outline: 0;
  border: 0;
  padding-left: 16px;
  &::placeholder {
    color: #484848;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }
`;
const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 0 10px;
  max-height: 200px;
  width: calc(100% - 20px);
  overflow: auto;
  position: absolute;
  cursor: pointer;
  z-index: 3;

  & {
    scrollbar-color: #35393a transparent;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{
  item?: string | null;
  isSelected?: boolean;
}>`
  padding: 6px 12px;
  font-size: 14px;
  ${(props) =>
    props.item &&
    css`
      font-family: ${props.item};
      padding: 6px 12px;
    `}

  &:hover {
    color: #f2d093;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
    `}
`;

const DropdownLabel = styled.span`
  padding: 14px 4px 8px;
  font-size: 10px;
  border-bottom: 1px solid #484848;
`;

const ToggleIcon = styled.button`
  border: 0;
  outline: 0;
  background-color: transparent;
`;

const FieldName = styled.span`
  color: #848484;
  font-family: Inter, san-serif;
  font-size: 10px;
  font-weight: 500;
`;
