import { toJS } from 'mobx';
import { ElementState } from '../renderer/ElementState';
import { Renderer } from '../renderer/Renderer';
import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
} from '../stores/VideoCreatorStore';
import { deepClone } from '../utility/deepClone';
import { handleSplitLayoutElement, ensureElementZPositions } from '../utility/general';
import { ExtraElementData } from '@src/types.ts/story';
import {
  adjustTrackNumbersToStartFromOne,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedNotWhiteSpaceElementIndexToLeft,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestRemovedIndexToRight,
  getClosestTextIndexToLeft,
  getClosestTextIndexToRight,
  mapToElementState,
} from './utils';

import {
  TranscriptChange,
  TranscriptClipboard,
  TranscriptElement,
  TranscriptTextElement,
} from '../types.ts/transcript';

import { v4 as uuid } from 'uuid';
import TranscriptionProcessor from './TranscriptionProcessor';

const PRECISION_EPS = 0.01;
export default class TranscriptionToVideoProcessor {
  private videoCreator: VideoCreatorStore;
  private renderer?: Renderer;
  private transcriptionProcessor: TranscriptionProcessor;
  private originalSource: Record<string, any> = {};

  constructor(
    videoCreator: VideoCreatorStore,
    transcriptionProcessor: TranscriptionProcessor,
  ) {
    this.videoCreator = videoCreator;
    this.transcriptionProcessor = transcriptionProcessor;
  }

  transcriptClipboard?: TranscriptClipboard;

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  setOriginalSource(source: Record<string, any>) {
    this.originalSource = source;
  }

  // if intoPosition is -1, then insert position is not known at cut time (todo?)
  // if toElement is text element and the next element is whitespace, then toElement should become the next element;
  // if toElement is a text and the next element is punctuation, then fromElement should be the next element after closest text element to left of fromElement, but if there is no elements before fromElement, then toElement should be the one before next text element after toElement;
  // if toElement is a punctuation or the last element, fromElement should be the next element after closest text element to left;
  // if toElement is a whitespace, then fromElement shouldn't be a whitespace or punctuation, it should be the next text element to the right;
  // if intoPosition >= 0, then insert position is known and cut range may be changed (todo?)
  getValidCutPositions(fromElement: number, toElement: number) {
    const elements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    let nextElementIndex = getClosestNotRemovedElementIndexToRight(
      toElement + 1,
      elements,
    );
    let prevElementIndex = getClosestNotRemovedElementIndexToLeft(
      fromElement - 1,
      elements,
    );
    // let prevToInsertIndex = getClosestNotRemovedElementIndexToLeft(
    //   intoPosition - 1,
    //   elements,
    // );
    let nextElement = nextElementIndex >= 0 ? elements[nextElementIndex] : null;
    let prevElement = prevElementIndex >= 0 ? elements[prevElementIndex] : null;
    // let prevToInsertElement =
    //   prevToInsertIndex >= 0 ? elements[prevToInsertIndex] : null;

    if (elements[toElement].type === 'text' && nextElement?.value === ' ') {
      toElement = nextElementIndex;
    } else if (elements[toElement].value === ' ') {
      fromElement = Math.min(
        getClosestTextIndexToRight(fromElement, elements),
        toElement,
      );
    } else if (
      (elements[toElement].type === 'text' && nextElement?.type === 'punct') ||
      (elements[toElement].type === 'punct' &&
        elements[toElement].value !== '\n') ||
      getClosestNotRemovedElementIndexToRight(toElement + 1, elements) === -1
    ) {
      let prevIndex;
      if (
        elements[toElement].type === 'text' &&
        nextElement?.type === 'punct'
      ) {
        prevIndex = getClosestTextIndexToLeft(fromElement - 1, elements);
      } else {
        prevIndex = getClosestNotRemovedNotWhiteSpaceElementIndexToLeft(
          fromElement - 1,
          elements,
        );
      }
      if (prevIndex >= 0) {
        fromElement = getClosestNotRemovedElementIndexToRight(
          prevIndex + 1,
          elements,
        );
      } else {
        fromElement = getClosestNotRemovedElementIndexToRight(0, elements);
        const nextTextIndex = getClosestNotRemovedTextIndexToRight(
          toElement + 1,
          elements,
        );
        if (nextTextIndex >= 0) {
          toElement = getClosestNotRemovedElementIndexToLeft(
            nextTextIndex - 1,
            elements,
          );
        } else {
          // nothing to cut
        }
      }
    }

    if (fromElement > toElement) {
      console.error('Invalid cut positions', fromElement, toElement, elements);
      throw Error('Invalid cut positions');
    }

    return { fromElement, toElement };
  }

  generateClipboardRanges(fromElement: number, toElement: number) {
    let fromIndex = fromElement;
    const ranges = [];
    while (fromIndex >= 0 && fromIndex <= toElement) {
      if (fromIndex === toElement) {
        ranges.push({ start: fromIndex, end: fromIndex });
        break;
      }
      const elements =
        this.transcriptionProcessor.getFinalTranscriptionElements();

      const nextRemovedIndex = getClosestRemovedIndexToRight(
        fromIndex,
        elements,
      );
      const lastNotRemovedElement =
        nextRemovedIndex > -1
          ? getClosestNotRemovedElementIndexToLeft(nextRemovedIndex, elements)
          : toElement;
      if (lastNotRemovedElement === -1) break;

      ranges.push({
        start: fromIndex,
        end: Math.min(lastNotRemovedElement, toElement),
      });

      fromIndex = getClosestNotRemovedElementIndexToRight(
        lastNotRemovedElement + 1,
        elements,
      );
    }
    // // debugger;
    return ranges;
  }

  /** Operations on transcription reflected in video tracks */
  async pasteFromClipboard(intoPosition: number) {
    const clipboard = this.transcriptClipboard;
    if (!clipboard) return;
    let addedPositions = 0;
    for (const range of clipboard.ranges) {
      const insertingBefore = intoPosition < range.start;
      // // debugger;
      await this.insertTextElements(
        range.start + (insertingBefore ? addedPositions : 0),
        range.end + 1 + (insertingBefore ? addedPositions : 0),
        intoPosition + addedPositions,
      );
      addedPositions += range.end - range.start + 1;
    }
    this.transcriptClipboard = undefined;
    return { fromIndex: intoPosition, toIndex: intoPosition + addedPositions };
  }

  async removeTextElements(
    fromElement: number,
    toElement: number,
    toClipboard: boolean = false,
    autoCorrect: boolean = false,
    overrides?: {
      cutPoints?: { cutFromTs?: number; cutToTs?: number };
      cutSkipTypes?: string[];
    },
    noRendererOutput?: {
      duration: number;
      source: Record<string, any>;
    },
  ) {
    const type = toClipboard ? 'cut' : 'remove';

    const { fromElement: newFromElement, toElement: newToElement } = autoCorrect
      ? this.getValidCutPositions(fromElement, toElement - 1)
      : { fromElement, toElement: toElement - 1 };

    if (toClipboard) {
      const ranges = this.generateClipboardRanges(newFromElement, newToElement);
      this.transcriptClipboard = {
        text: this.transcriptionProcessor
          .getFinalTranscriptionElements()
          .slice(newFromElement, newToElement + 1)
          .map((el) => el.value || '')
          .join(''),
        ranges: ranges,
      };
    }

    fromElement = newFromElement;
    toElement = newToElement + 1;
    // debugger;
    // Logic to remove a segment from both video and transcription
    const cutPoints = this.getCutPointsForTranscriptionElements(
      fromElement,
      toElement,
    );
    if (!cutPoints) return;
    if (overrides?.cutPoints?.cutFromTs != null) {
      cutPoints.timeBufferBefore +=
        cutPoints.cutFromTs - (overrides.cutPoints.cutFromTs || 0);
      cutPoints.cutFromTs = overrides.cutPoints.cutFromTs;
    }
    if (overrides?.cutPoints?.cutToTs != null) {
      cutPoints.cutToTs = overrides.cutPoints.cutToTs;
    }
    const { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter } = cutPoints;

    if (cutFromTs === cutToTs) {
      // no text elements between fromElement and toElement, or elements are removed
      const removeChange = this.getRemoveChange(
        fromElement,
        toElement,
        0,
        0,
        type,
      );
      removeChange.command = `removeTextElements(${fromElement}, ${toElement}) - fromTs: ${cutFromTs}, toTs: ${cutToTs}`;
      this.transcriptionProcessor.applyChange(removeChange);
      return;
    }

    // console.log('timings 2', timeBufferBefore, timeBufferAfter, cutFromTs, cutToTs)
    await this.cutSpecifiedTypeTracksSegment(
      cutFromTs,
      cutToTs,
      overrides?.cutSkipTypes || ['audio'],
      noRendererOutput,
    );
    const removeChange = this.getRemoveChange(
      fromElement,
      toElement,
      timeBufferBefore,
      timeBufferAfter,
      type,
    );
    removeChange.command = `removeTextElements(${fromElement}, ${toElement}) - fromTs: ${cutFromTs}, toTs: ${cutToTs}`;
    this.transcriptionProcessor.applyChange(removeChange);
  }

  async cropVideoToKeepTextElements(
    fromElement: number,
    toElement: number,
    noRendererOutput?: {
      duration: number;
      source: Record<string, any>;
    },
  ): Promise<void> {
    const stateDuration = noRendererOutput
      ? noRendererOutput.duration
      : this.renderer?.state?.duration;
    if (!stateDuration) {
      throw new Error('No duration provided to cropVideoToKeepTextElements');
    }

    const cutPointsToKeep = this.getCutPointsForTranscriptionElements(
      fromElement,
      toElement,
    );
    if (!cutPointsToKeep) return;

    for (const part of ['tail', 'head']) {
      //order matters
      const startIndex = part === 'tail' ? toElement + 1 : 0;
      const endIndex =
        part === 'tail'
          ? this.transcriptionProcessor.getFinalTranscriptionElements().length
          : fromElement;

      // CUT AFTER toElement
      const cutPointsToDelete = this.getCutPointsForTranscriptionElements(
        startIndex,
        endIndex,
      );
      if (!cutPointsToDelete) continue;
      let { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter } =
        cutPointsToDelete;

      if (part === 'tail') {
        cutFromTs = cutPointsToKeep.cutToTs;
        cutToTs = cutPointsToDelete.cutToTs;
        timeBufferBefore =
          timeBufferBefore +
          cutPointsToDelete.cutFromTs -
          cutPointsToKeep.cutToTs;
      } else {
        cutFromTs = cutPointsToDelete.cutFromTs;
        cutToTs = cutPointsToKeep.cutFromTs;
        timeBufferAfter =
          timeBufferAfter +
          cutPointsToKeep.cutFromTs -
          cutPointsToDelete.cutToTs;
      }

      // // debugger;
      let extraDeletedTime = 0;
      if (part === 'tail') {
        await this.cutSpecifiedTypeTracksSegment(
          cutFromTs,
          Infinity,
          [],
          noRendererOutput,
        );
        // extraDeletedTime = state.duration - cutToTs;
      } else {
        await this.cutSpecifiedTypeTracksSegment(
          0,
          cutToTs,
          [],
          noRendererOutput,
        );
        extraDeletedTime = cutFromTs;
      }
      // // debugger;
      const removeChange = this.getRemoveChange(
        startIndex,
        endIndex,
        timeBufferBefore + extraDeletedTime,
        timeBufferAfter,
      );
      removeChange.command = `cropVideoToKeepTextElements(${fromElement}, ${toElement}) - fromTs: ${cutFromTs}, toTs: ${cutToTs}`;
      this.transcriptionProcessor.applyChange(removeChange);
    }
  }

  async cropVideoToKeepTextElementsInMultiplePlaces(
    ranges: { fromElement: number; toElement: number }[],
    noRendererOutput?: {
      duration: number;
      source: Record<string, any>;
    },
  ) {
    const stateDuration = noRendererOutput
      ? noRendererOutput.duration
      : this.renderer?.state?.duration;
    if (!stateDuration) {
      throw new Error(
        'No duration provided to cropVideoToKeepTextElementsInMultiplePlaces',
      );
    }

    const lastElement =
      this.transcriptionProcessor.getFinalTranscriptionElements().length - 1;
    const rangeInverses = this.getRangeInverses([...ranges], lastElement);

    for (const range of rangeInverses) {
      if (range.fromElement === 0 || range.toElement === lastElement) {
        range.toElement++;
      }
      await this.removeTextElements(
        range.fromElement,
        range.toElement,
        false,
        false,
        {
          cutPoints: {
            cutFromTs: range.fromElement === 0 ? 0 : undefined,
            cutToTs: range.toElement === lastElement + 1 ? Infinity : undefined,
          },
          cutSkipTypes: [],
        },
        noRendererOutput,
      );
    }
  }

  private getRangeInverses(
    ranges: { fromElement: number; toElement: number }[],
    lastElement: number,
  ): { fromElement: number; toElement: number }[] {
    ranges.sort((a, b) => a.fromElement - b.fromElement);

    let rangeInverses: {
      fromElement: number;
      toElement: number;
    }[] = [];
    let prevToElement = 0;
    for (let i = 0; i < ranges.length; i++) {
      const { fromElement, toElement } = ranges[i];

      if (fromElement > prevToElement) {
        rangeInverses.push({
          fromElement: prevToElement,
          toElement: fromElement - 1,
        });
      }

      prevToElement = toElement + 1;
    }

    if (prevToElement <= lastElement) {
      rangeInverses.push({
        fromElement: prevToElement,
        toElement: lastElement,
      });
    }

    return rangeInverses;
  }

  async insertTextElements(
    fromElement: number,
    toElement: number,
    intoPosition: number,
  ) {
    // debugger;
    if (toElement <= fromElement || fromElement < 0) return;
    const originalElements =
      this.transcriptionProcessor.getTranscriptionElements();
    const finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();

    // find start time and end time of moved text segment in original transcription
    const fromTextElement = getClosestTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextElement = getClosestTextIndexToLeft(
      toElement - 1,
      finalElements,
    );

    const originalFromIndex = finalElements[fromTextElement].initial_index;
    const originalToIndex = finalElements[toTextElement].initial_index;

    const timeBufferBefore =
      (finalElements[fromTextElement] as TranscriptTextElement)
        .buffer_before_ts || 0;
    const timeBufferAfter =
      (finalElements[toTextElement] as TranscriptTextElement).buffer_after_ts ||
      0;

    let fromTime = originalElements[originalFromIndex].ts! - timeBufferBefore;
    let toTime = originalElements[originalToIndex].end_ts! + timeBufferAfter;

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      intoPosition - 1,
      finalElements,
    );

    let afterTime = 0;
    if (afterTextElement >= 0) {
      const afterEl = finalElements[afterTextElement] as TranscriptTextElement;
      afterTime = afterEl.end_ts + (afterEl.buffer_after_ts || 0);
      // getTimeBufferAfter(afterTextElement, originalElements);
    } else {
      // insert at the beginning
      const firstNonRemovedElement = finalElements[
        getClosestNotRemovedTextIndexToRight(0, finalElements)
      ] as TranscriptTextElement;

      afterTime = firstNonRemovedElement
        ? firstNonRemovedElement.ts -
          (firstNonRemovedElement.buffer_before_ts || 0)
        : 0;
    }
    // debugger;
    // insert video segment corresponding to moved text segment after afterElement
    await this.insertVideoSegment(fromTime, toTime, afterTime);

    const cutBufferBefore =
      (finalElements[fromTextElement] as TranscriptTextElement)
        .buffer_before_ts || 0;

    const cutFromTs = finalElements[fromTextElement].ts! - cutBufferBefore;

    // debugger;

    const shiftChange1: TranscriptChange = {
      type: 'shift',
      index: intoPosition,
      count: finalElements.length - intoPosition,
      newIndex: intoPosition,
      timeShift: toTime - fromTime,
      datetime: new Date().toISOString(),
      command: `insertTextElements(${fromElement}, ${toElement}, ${intoPosition})`,
    };

    const shiftChange2: TranscriptChange = {
      type: 'shift',
      index: fromElement,
      count: toElement - fromElement,
      newIndex: intoPosition,
      timeShift:
        afterTime - cutFromTs - (cutFromTs > afterTime ? toTime - fromTime : 0),
      datetime: new Date().toISOString(),
      command: `insertTextElements(${fromElement}, ${toElement}, ${intoPosition})`,
    };

    this.transcriptionProcessor.applyChange(shiftChange1);
    this.transcriptionProcessor.applyChange(shiftChange2);
  }

  /** remove transcription elements between fromElement and toElement positions and place them next to afterElement */
  async moveTextElements(
    fromElement: number,
    toElement: number,
    afterElement: number,
  ) {
    // debugger;
    if (toElement <= fromElement || fromElement < 0) return;
    const originalElements =
      this.transcriptionProcessor.getTranscriptionElements();
    const finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    // find start time and end time of moved text segment in original transcription
    const fromTextElement = getClosestNotRemovedTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextElement = getClosestNotRemovedTextIndexToLeft(
      toElement - 1,
      finalElements,
    );

    const originalFromIndex = finalElements[fromTextElement].initial_index;
    const originalToIndex = finalElements[toTextElement].initial_index;

    const timeBufferBefore =
      (originalElements[originalFromIndex] as TranscriptTextElement)
        .buffer_before_ts || 0;
    const timeBufferAfter =
      (originalElements[originalFromIndex] as TranscriptTextElement)
        .buffer_after_ts || 0;

    const fromTime = originalElements[originalFromIndex].ts! - timeBufferBefore;
    const toTime = originalElements[originalToIndex].end_ts! + timeBufferAfter;

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      afterElement - 1,
      finalElements,
    );

    let afterTime = 0;
    if (afterTextElement >= 0) {
      const afterEl = finalElements[afterTextElement] as TranscriptTextElement;
      afterTime = afterEl.end_ts + (afterEl.buffer_after_ts || 0);
      // getTimeBufferAfter(afterTextElement, originalElements);
    } else {
      afterTime = originalElements[0].ts!; // todo check
      // getTimeBufferBefore(
      //   getClosestNotRemovedTextIndexToLeft(0, originalElements),
      //   originalElements,
      // );
    }
    // debugger;
    // insert video segment corresponding to moved text segment after afterElement
    await this.insertVideoSegment(fromTime, toTime, afterTime);

    // debugger;
    // cut video segment from fromElement to toElement related to final transcription

    const cutBufferBefore =
      (finalElements[fromTextElement] as TranscriptTextElement)
        .buffer_before_ts || 0;
    const cutBufferAfter =
      (finalElements[toTextElement - 1] as TranscriptTextElement)
        .buffer_after_ts || 0;

    const cutFromTs = finalElements[fromTextElement].ts! - cutBufferBefore;
    const cutToTs = finalElements[toTextElement].end_ts! + cutBufferAfter;

    const shiftTs = afterTime - cutFromTs > 0 ? 0 : cutToTs - cutFromTs;
    await this.cutSpecifiedTypeTracksSegment(
      cutFromTs + shiftTs,
      cutToTs + shiftTs,
      [],
    );
    // debugger;
    // apply three shifts to final transcription using transcriptionProcessor.applyChange:
    // 1. shift transcription elements after afterElement to the right by duration of moved segment
    // 2. shift transcription elements from fromElement to toElement to the afterElement position
    // 3. shift transcription elements after toElement to the left by duration of moved segment

    // shift 1:
    this.transcriptionProcessor.applyChange({
      type: 'shift',
      index: afterElement,
      count: finalElements.length - afterElement,
      newIndex: afterElement,
      timeShift: toTime - fromTime,
      datetime: new Date().toISOString(),
      command: `moveTextElements(${fromElement}, ${toElement}, ${afterElement}) - shift1`,
    });

    //shift 2:
    this.transcriptionProcessor.applyChange({
      type: 'shift',
      index: fromElement,
      count: toElement - fromElement,
      newIndex: afterElement,
      timeShift: afterTime - cutFromTs - shiftTs,
      datetime: new Date().toISOString(),
      command: `moveTextElements(${fromElement}, ${toElement}, ${afterElement}) - shift2`,
    });

    //shift 3:
    this.transcriptionProcessor.applyChange({
      type: 'shift',
      index: toElement,
      count:
        this.transcriptionProcessor.getFinalTranscriptionElements().length -
        toElement,
      newIndex: toElement,
      timeShift: -(cutToTs - cutFromTs),
      datetime: new Date().toISOString(),
      command: `moveTextElements(${fromElement}, ${toElement}, ${afterElement}) - shift3`,
    });
  }

  async restoreTextElementsFromOriginal(
    fromElement: number,
    toElement: number,
  ) {
    // debugger;
    const originalElements =
      this.transcriptionProcessor.getTranscriptionElements();
    const finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    if (
      fromElement < 0 ||
      toElement <= fromElement ||
      toElement > finalElements.length
    ) {
      throw Error('Invalid elements range');
    }

    const fromTextIndex = getClosestTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextIndex = getClosestTextIndexToLeft(toElement - 1, finalElements);

    if (fromTextIndex === -1 || toTextIndex === -1) return;

    const fromTextElement = finalElements[fromTextIndex].initial_index;
    const toTextElement = finalElements[toTextIndex].initial_index;

    if (fromTextElement > toTextElement || fromTextElement === -1) {
      // that means selection doesn't contain any text element
      this.transcriptionProcessor.applyChange({
        type: 'restore',
        index: fromElement,
        count: toElement - fromElement,
        newTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
        datetime: new Date().toISOString(),
        command: `restoreTextElementsFromOriginal(${fromElement}, ${toElement}) - no text elements selected`,
      });
      return;
    }

    if (fromTextElement === -1 || toTextElement === -1) return;

    //TODO use initial_index

    // find text elements closest to outside of boundaries (to cut video between words, not on start)
    const finalFromElement = finalElements[
      fromTextIndex
    ] as TranscriptTextElement;
    const finalToElement = finalElements[toTextIndex] as TranscriptTextElement;
    // debugger;
    let timeBufferBefore =
      finalFromElement.buffer_before_ts ||
      0 - (finalFromElement.trim_start || 0);
    let timeBufferAfter =
      finalToElement.buffer_after_ts || 0 - (finalToElement.trim_end || 0);

    let restoreFromTs =
      originalElements[fromTextElement].ts! - timeBufferBefore;
    let restoreToTs = originalElements[toTextElement].end_ts! + timeBufferAfter;

    if (restoreToTs - restoreFromTs <= 0) {
      // negative or zero duration segment, just restore the text
      this.transcriptionProcessor.applyChange({
        type: 'restore',
        index: fromElement,
        count: toElement - fromElement,
        newTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
        datetime: new Date().toISOString(),
        command: `restoreTextElementsFromOriginal(${fromElement}, ${toElement}) - zero/negative duration segment`,
      });
      return;
    }

    // restoreFromTs = this.getClosestFrameTime(restoreFromTs);
    // restoreToTs = this.getClosestFrameTime(restoreToTs);
    // todo handle restoreToTs === restoreFromTs
    // timeBufferBefore = originalElements[fromTextElement].ts! - restoreFromTs;
    // timeBufferAfter = restoreToTs - originalElements[toTextElement].end_ts!;

    const afterTextElement = getClosestNotRemovedTextIndexToLeft(
      fromElement,
      finalElements,
    );

    let intoTs = 0;
    // debugger;
    if (afterTextElement >= 0) {
      // insert somewhere in the middle
      const afterTextTimeBuffer =
        (finalElements[afterTextElement] as TranscriptTextElement)
          .buffer_after_ts || 0;
      intoTs = finalElements[afterTextElement].end_ts! + afterTextTimeBuffer;
      // intoTs = this.getClosestFrameTime(intoTs);
    } else {
      // insert at the beginning
      const firstNonRemovedElement = finalElements[
        getClosestNotRemovedTextIndexToRight(0, finalElements)
      ] as TranscriptTextElement;

      intoTs = firstNonRemovedElement
        ? firstNonRemovedElement.ts -
          (firstNonRemovedElement.buffer_before_ts || 0)
        : 0;
      // intoTs = this.getClosestFrameTime(intoTs);
    }
    // debugger;
    const newTs = await this.insertVideoSegment(
      restoreFromTs,
      restoreToTs,
      intoTs,
    );

    this.transcriptionProcessor.applyChange({
      type: 'restore',
      index: fromElement,
      count: toElement - fromElement,
      newTs,
      timeBufferBefore,
      timeBufferAfter,
      datetime: new Date().toISOString(),
      command: `restoreTextElementsFromOriginal(${fromElement}, ${toElement})`,
    });
  }

  private getCutPointsForTranscriptionElements(
    fromElement: number,
    toElement: number,
  ) {
    if (fromElement >= toElement || fromElement < 0) return null;
    const finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    // fromElement including, toElement excluding
    // find text elements (handling case when selection starts or ends on punctuation elements)
    const fromTextElement = getClosestNotRemovedTextIndexToRight(
      fromElement,
      finalElements,
    );
    const toTextElement = getClosestNotRemovedTextIndexToLeft(
      toElement - 1,
      finalElements,
    );

    if (fromTextElement > toTextElement || fromTextElement === -1) {
      // that means selection doesn't contain any text element
      return {
        cutFromTs: 0,
        cutToTs: 0,
        timeBufferBefore: 0,
        timeBufferAfter: 0,
      };
    }

    if (fromTextElement === -1 || toTextElement === -1) return null;

    let timeBufferBefore =
      (finalElements[fromTextElement] as TranscriptTextElement)
        .buffer_before_ts || 0;
    let timeBufferAfter =
      (finalElements[toTextElement] as TranscriptTextElement).buffer_after_ts ||
      0;

    // TODO CHECK buffers between words are calculated differently from right side vs from left side

    let cutFromTs = Math.max(
      0,
      finalElements[fromTextElement].ts! - timeBufferBefore,
    );
    let cutToTs = finalElements[toTextElement].end_ts! + timeBufferAfter;
    cutFromTs = this.getClosestFrameTime(cutFromTs);
    cutToTs = this.getClosestFrameTime(cutToTs);

    // if (cutToTs === cutFromTs) {
    //   cutToTs = cutFromTs + 1 / DEFAULT_FPS;
    // }

    timeBufferBefore = finalElements[fromTextElement].ts! - cutFromTs;
    timeBufferAfter = cutToTs - finalElements[toTextElement].end_ts!;

    return { cutFromTs, cutToTs, timeBufferBefore, timeBufferAfter };
  }

  private getClosestFrameTime(time: number) {
    // const frameRate = this.originalSource.frame_rate || DEFAULT_FPS;
    // const frameDuration = 1 / frameRate;
    // debugger;
    const precision = 0.001;
    const frameTime = Math.round(time / precision) / (1 / precision);
    return frameTime;
  }

  private getRemoveChange(
    fromElement: number,
    toElement: number,
    timeBufferBefore: number,
    timeBufferAfter: number,
    type: 'remove' | 'cut' = 'remove',
  ): TranscriptChange {
    const newChange: TranscriptChange = {
      type,
      index: fromElement,
      count: toElement - fromElement,
      timeBufferBefore,
      timeBufferAfter,
      oldValue: this.transcriptionProcessor
        .getFinalTranscriptionElements()
        .slice(fromElement, toElement)
        .map((el) => el.value || '')
        .join(''),
      newValue: null,
      datetime: new Date().toISOString(),
      version: 2,
    };
    return newChange;
  }

  private async cutSpecifiedTypeTracksSegment(
    fromTs: number,
    toTs: number,
    skipTypes: string[],
    noRendererOutput?: {
      source: Record<string, any>;
    },
  ) {
    // Now using ES6 imports at the top of the file instead of require here
    
    const source = noRendererOutput
      ? noRendererOutput.source
      : this.renderer?.getSource();
    const elements = noRendererOutput
      ? noRendererOutput.source.elements
      : this.renderer?.getElements();
    if (!source) {
      throw new Error(
        'No source was provided to cutSpecifiedTypeTracksSegment',
      );
    }
    if (!elements) {
      throw new Error(
        'No elements was provided to cutSpecifiedTypeTracksSegment',
      );
    }
    
    const newTracks = [];
    const elementsInComposition = {} as any;
    
    // Keep track of split elements to handle layout relationships after cutting
    const splitElementPairs = [];

    // find composition elements
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].source.type === 'composition') {
        elements[i].elements?.forEach((el: any) => {
          elementsInComposition[el.source.id] = el;
        });
      }
    }

    for (let i = 0; i < elements.length; i++) {
      const elementTime = elements[i].globalTime;
      const elementDuration = elements[i].duration;
      const isImageElement = this.videoCreator.isImageElement(elements[i]);
      if (
        (elements[i].source.type === 'composition' ||
          elementsInComposition[elements[i].source.id]) &&
        !this.videoCreator.isImageElementComposition(elements[i])
      ) {
        // skip elements from compositions
        continue;
      }

      if (skipTypes.includes(elements[i].source.type)) {
        // skip audio tracks from cutting it
        newTracks.push(elements[i].source);
        continue;
      }

      // console.log('all numbers', elements[i].globalTime, elements[i].duration, fromTs, toTs, elements[i].source.trim_start)

      if (isImageElement) {
        let newTrack: any = {
          ...elements[i].source,
          // id: uuid()
        };
        const _mapIfComposition = (
          newSource: any,
          currentElement: ElementState,
        ) => {
          if (this.videoCreator.isImageElementComposition(currentElement)) {
            newSource.elements = currentElement.elements?.map((e) => ({
              ...e.source,
              id: uuid(),
              duration: newSource.duration,
            }));
          }
          return newSource;
        };

        if (elementTime < fromTs && elementTime + elementDuration > toTs) {
          newTrack.duration = elementDuration - toTs + fromTs;
          newTrack = _mapIfComposition(newTrack, elements[i]);
          newTracks.push(newTrack);
        } else if (
          elementTime < fromTs &&
          elementTime + elementDuration > fromTs
        ) {
          newTrack.duration = fromTs - (elementTime || 0);
          newTrack = _mapIfComposition(newTrack, elements[i]);
          newTracks.push(newTrack);
        } else if (elementTime < toTs && elementTime + elementDuration > toTs) {
          newTrack.time = fromTs;
          newTrack.duration = elementDuration - toTs + (elementTime || 0);
          newTrack.trim_start =
            parseFloat(elements[i].source.trim_start || '0') +
            toTs -
            (elementTime || 0);
          newTrack = _mapIfComposition(newTrack, elements[i]);
          newTracks.push(newTrack);
        }
      } else {
        // cut all other tracks
        if (elementTime < fromTs && elementTime + elementDuration > fromTs) {
          // Head
          const newTrackId = uuid();
          const newTrack: any = { ...elements[i].source, id: newTrackId };
          (newTrack.duration = fromTs - elementTime);
          newTracks.push(newTrack);
          
          // Record this split for layout handling
          splitElementPairs.push({
            originalId: elements[i].source.id,
            newId: newTrackId
          });
        }

        if (elementTime < toTs && elementTime + elementDuration > toTs) {
          // Tail
          const newTrackId = uuid();
          const newTrack: any = {
            ...elements[i].source,
            id: newTrackId,
            time: fromTs,
          };
          newTrack.duration = elementDuration - toTs + (elementTime || 0);
          newTrack.trim_start =
            parseFloat(elements[i].source.trim_start || '0') +
            toTs -
            (elementTime || 0);
          newTracks.push(newTrack);
          
          // Record this split for layout handling
          splitElementPairs.push({
            originalId: elements[i].source.id,
            newId: newTrackId
          });
        }
      }

      // Keep all before and after
      if (elementTime + elementDuration <= fromTs) {
        // Before', elements[i]);
        newTracks.push({
          ...elements[i].source,
          ...(this.videoCreator.isImageElementComposition(elements[i]) && {
            elements: elements[i].elements?.map((e: any) => ({
              ...e.source,
            })),
          }),
        });
      }

      if (elementTime >= toTs) {
        // After
        newTracks.push({
          ...elements[i].source,
          // id: uuid(),
          time: elementTime - (toTs - fromTs),
          ...(this.videoCreator.isImageElementComposition(elements[i]) && {
            elements: elements[i].elements?.map((e: any) => ({
              ...e.source,
              id: uuid(),
            })),
          }),
        });
      }
    }

    // Before replacing elements, find and process any layout-related elements
    // This helps maintain layout relationships when elements are cut or split
    const extraElementData = this.videoCreator.currentVideo?.extraElementData || {};
    
    // Track layout information directly from ExtraElementData
    // This will help us maintain layout relationships correctly when cutting
    
    // First identify all layout elements and their relationships
    const layoutElements = new Map<string, ExtraElementData>();
    
    // Store all layout-related elements
    for (const [id, rawData] of Object.entries(extraElementData)) {
      // Type cast to ExtraElementData to access layout properties
      const data = rawData as ExtraElementData;
      
      // Only store elements that have layout properties
      if (data.layoutPosition && data.layoutAssociatedIds && data.layoutAssociatedIds.length > 0) {
        layoutElements.set(id, data);
      }
    }
    
    // Now check if any elements we're modifying are layout-related
    for (const element of elements) {
      const id = element.source.id;
      
      // Check if this is a layout element
      const layoutData = layoutElements.get(id);
      if (!layoutData) continue;
      
      // Case 1: This is a hidden parent element
      if (layoutData.layoutPosition === 'hidden' && layoutData.layoutAssociatedIds) {
        // Search for this element in newTracks
        const updatedElement = newTracks.find(track => track.id === id);
        if (!updatedElement) continue;
        
        // For each child element (which are the associated composition elements)
        for (const childId of layoutData.layoutAssociatedIds) {
          const childElement = newTracks.find(track => track.id === childId);
          if (childElement) {
            // Sync time-related properties
            childElement.time = updatedElement.time;
            childElement.duration = updatedElement.duration;
            if (updatedElement.trim_start) childElement.trim_start = updatedElement.trim_start;
            if (updatedElement.trim_end) childElement.trim_end = updatedElement.trim_end;
          }
        }
      }
      
      // Case 2: This is a layout child (top or bottom composition)
      if ((layoutData.layoutPosition === 'top' || layoutData.layoutPosition === 'bottom') && 
          layoutData.layoutAssociatedIds && layoutData.layoutAssociatedIds.length > 0) {
        
        // Find this element in newTracks
        const updatedElement = newTracks.find(track => track.id === id);
        if (!updatedElement) continue;
        
        // The first associated ID is the hidden parent
        const parentId = layoutData.layoutAssociatedIds[0];
        
        // Update parent
        const parentElement = newTracks.find(track => track.id === parentId);
        if (parentElement) {
          // Sync time-related properties
          parentElement.time = updatedElement.time;
          parentElement.duration = updatedElement.duration;
          if (updatedElement.trim_start) parentElement.trim_start = updatedElement.trim_start;
          if (updatedElement.trim_end) parentElement.trim_end = updatedElement.trim_end;
        }
        
        // Find and update sibling elements
        // First get the parent's data to find its children
        const parentData = layoutElements.get(parentId);
        if (parentData && parentData.layoutAssociatedIds) {
          // Update all siblings (other composition elements associated with the parent)
          for (const siblingId of parentData.layoutAssociatedIds) {
            if (siblingId !== id) { // Skip the current element
              const siblingElement = newTracks.find(track => track.id === siblingId);
              if (siblingElement) {
                // Sync time-related properties
                siblingElement.time = updatedElement.time;
                siblingElement.duration = updatedElement.duration;
                if (updatedElement.trim_start) siblingElement.trim_start = updatedElement.trim_start;
                if (updatedElement.trim_end) siblingElement.trim_end = updatedElement.trim_end;
              }
            }
          }
        }
      }
    }
    
    source.elements = newTracks;
    
    // Update source
    // Apply z-index to all elements
    const sourceWithZIndexes = ensureElementZPositions(source);
    
    if (this.renderer && !noRendererOutput) {
      // Apply modifications through creatomate
      await this.renderer.setSource(
        adjustTrackNumbersToStartFromOne(sourceWithZIndexes),
        true,
      );
      this.videoCreator.frameLockedTracks = [];
      
      // Process layout elements for splits
      for (const pair of splitElementPairs) {
        await handleSplitLayoutElement(this.videoCreator, pair.originalId, pair.newId);
      }
    } else if (noRendererOutput) {
      noRendererOutput.source = {
        ...sourceWithZIndexes,
        elements: sourceWithZIndexes.elements.map(mapToElementState),
      };
      
      // Even for noRendererOutput, we need to update the element associations
      for (const pair of splitElementPairs) {
        await handleSplitLayoutElement(this.videoCreator, pair.originalId, pair.newId);
      }
    }
  }

  private async restoreSpecifiedTypeTracksSegment(
    source: Record<string, any>,
    fromTs: number,
    toTs: number,
    intoTs: number,
    skipTypes: string[],
  ) {
    const elements = source.elements;
    const newTracks = [];

    const isImageComposition = (element: Record<string, any>) => {
      if (element.type === 'composition') {
        return element?.elements?.some((e: any) => e.type === 'image');
      }
      return false;
    };

    const elementsInComposition = {} as any;

    for (let element of elements) {
      if (element.type === 'composition') {
        element.elements?.forEach((el: any) => {
          elementsInComposition[el.id] = el;
        });
      }
    }

    for (let el of elements) {
      const elementTime = el.time;

      if (elementsInComposition[el.id]) continue;
      if (
        (el.type === 'composition' && !isImageComposition(el)) ||
        skipTypes.includes(el.type) ||
        el.type === 'video'
      ) {
        newTracks.push(el);
        continue;
      }

      if (elementTime >= intoTs) {
        newTracks.push({
          ...el,
          // id: uuid(),
          time: elementTime + (toTs - fromTs),
          ...(isImageComposition(el) && {
            elements: el.elements?.map((e: any) => ({
              ...e,
              id: uuid(),
            })),
          }),
        });
      } else {
        newTracks.push(el);
      }
    }

    source.elements = newTracks;
    return source;
  }

  private async insertVideoSegment(
    fromTs: number,
    toTs: number,
    intoTs: number,
  ): Promise<number> {
    // debugger;
    const source = this.renderer!.getSource();
    // const elements = this.renderer!.getElements();
    const trackNumber = source.elements.find(
      (el: any) => el.type === 'video',
    )!.track;

    let insertTrackIndex = -1;
    let nextTrackIndex = -1;
    let skippedTracks = 0;
    let newTs = -1;

    const newTracks: Record<string, any>[] = [];
    // debugger;
    for (let i = 0; i < source.elements.length; i++) {
      const elementTrack = parseInt(source.elements[i].track);
      const elementTime = parseFloat(source.elements[i].time);
      const elementDuration = parseFloat(source.elements[i].duration);
      const elementTrimStart = parseFloat(source.elements[i].trim_start || '0');
      if (
        elementTrack === trackNumber &&
        elementTime < intoTs - PRECISION_EPS &&
        elementTime + elementDuration >= intoTs - PRECISION_EPS
      ) {
        // insertion place
        insertTrackIndex = newTracks.length;
        newTs =
          parseFloat(source.elements[i].time) +
          parseFloat(source.elements[i].duration);

        if (Math.abs(newTs - intoTs) < PRECISION_EPS) {
          // no extra cut
          newTracks.push(source.elements[i]);
        } else {
          // extra cut
          newTs = intoTs;
          newTracks.push({
            ...source.elements[i],
            duration: intoTs - elementTime,
          });
          newTracks.push({
            ...source.elements[i],
            id: uuid(),
            time: intoTs + toTs - fromTs,
            duration: elementTime + elementDuration - intoTs,
            trim_start: elementTrimStart + intoTs - elementTime,
          });
          nextTrackIndex = insertTrackIndex + 1;
        }

        continue;
      }

      if (
        elementTrack === trackNumber &&
        elementTime >= intoTs - PRECISION_EPS
      ) {
        // after insertion place
        // if (
        //   nextTrackIndex === -1 &&
        //   toTs - (elementTrimStart + source.elements[i].duration) >
        //     PRECISION_EPS
        // ) {
        //   skippedTracks++;
        //   continue;
        // }
        newTracks.push({
          ...source.elements[i],
          time: elementTime + toTs - fromTs,
        });
        if (nextTrackIndex === -1) {
          nextTrackIndex = newTracks.length - 1;
        }
        continue;
      }

      if (
        elementTrack === trackNumber &&
        elementTime + elementDuration < intoTs
      ) {
        // before insertion place
        newTracks.push(source.elements[i]);
        continue;
      }
    }
    // debugger;

    //TODO REFACTOR
    if (insertTrackIndex >= 0) {
      // INSERT SOMEWHERE IN THE MIDDLE
      const insertTrackTrimStart = parseFloat(
        newTracks[insertTrackIndex].trim_start || '0',
      );
      let tracksToRemove = 0;
      let trackToInsert;

      trackToInsert = {
        ...newTracks[insertTrackIndex],
        id: uuid(),
        time: newTs,
        duration: toTs - fromTs,
        trim_start: fromTs,
      };

      if (
        Math.abs(
          fromTs -
            insertTrackTrimStart -
            parseFloat(newTracks[insertTrackIndex].duration),
        ) < PRECISION_EPS
      ) {
        // join on start
        tracksToRemove = 1;
        trackToInsert = {
          ...newTracks[insertTrackIndex],
          id: uuid(),
          duration: toTs - insertTrackTrimStart, //elements[insertTrackIndex].globalTime,
        };
      }

      if (
        nextTrackIndex >= 0 &&
        Math.abs(
          parseFloat(newTracks[nextTrackIndex].trim_start || '0') - toTs,
        ) < PRECISION_EPS
      ) {
        // join on end
        trackToInsert = {
          ...trackToInsert,
          duration:
            parseFloat(newTracks[nextTrackIndex].duration) +
            trackToInsert.duration,
        };
        newTracks.splice(nextTrackIndex - skippedTracks, 1);
      }

      newTracks.splice(insertTrackIndex, tracksToRemove, trackToInsert);
    } else {
      // INSERT AT THE BEGINNING
      newTs = 0;

      let trackToInsert;
      // // debugger;
      trackToInsert = {
        ...this.originalSource.elements.find((el: any) => el.type === 'video'), // in case all elements are deleted take original elements
        id: uuid(),
        time: newTs,
        duration: toTs - fromTs,
        trim_start: fromTs,
      };

      if (
        nextTrackIndex >= 0 &&
        Math.abs(
          parseFloat(newTracks[nextTrackIndex].trim_start || '0') - toTs,
        ) < PRECISION_EPS
      ) {
        // join on end
        trackToInsert = {
          ...trackToInsert,
          duration:
            parseFloat(newTracks[nextTrackIndex].duration) +
            trackToInsert.duration,
        };
        newTracks.splice(nextTrackIndex - skippedTracks, 1);
      }
      newTracks.splice(0, 0, trackToInsert);
    }

    //@ts-ignore
    newTracks.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));

    // // debugger;
    source.elements = source.elements
      .filter((el: any) => el.track !== trackNumber)
      .concat(newTracks);

    // console.log('new source', source);
    // return;
    await this.restoreSpecifiedTypeTracksSegment(source, fromTs, toTs, intoTs, [
      'audio',
    ]);

    delete source.duration;
    await this.renderer!.setSource(source, true);
    return newTs;
  }
}
