import React from 'react';
type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const LeftRightIcon = (props: Props) => {
  const {
    width = '16',
    height = '16',
    viewBox = '0 0 16 16',
    strokeColor = '#F2D093',
  } = props;
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none">
      {/* Rotated version of UpDownIcon */}
      <path d="M1.61108 8H14.3889" stroke={strokeColor} />
      <path
        d="M10.5554 4.16666L14.3888 8.00001L10.5554 11.8333"
        stroke={strokeColor}
      />
      <path
        d="M5.44442 4.16666L1.61108 8.00001L5.44442 11.8333"
        stroke={strokeColor}
      />
    </svg>
  );
};

export default LeftRightIcon;