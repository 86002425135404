import { v4 as uuid } from 'uuid';

type ElementProperties = Record<
  string,
  string | number | boolean | Record<string, any> | null | undefined
>;

export const getImageWithBlackFrameElements = (
  imageElementOverrides: { source: string } & ElementProperties,
  shapeOverrides?: ElementProperties,
): [ElementProperties, ElementProperties] => [
  {
    type: 'image',
    fit: 'contain',
    x: '50%',
    y: '50%',
    height: '100%',
    locked: true,
    color_overlay: '',
    ...imageElementOverrides,
    track: 2,
    time: 0,
    id: uuid(),
    z_index: 2 // Ensure proper z-index
  },
  {
    type: 'shape',
    width: '100%',
    height: '100%',
    x: '50%',
    y: '50%',
    fill_color: '#000',
    locked: true,
    path: 'M 0 0 L 100 0 L 100 100 L 0 100 L 0 0 Z',
    ...shapeOverrides,
    track: 1,
    time: 0,
    id: uuid(),
    z_index: 1 // Ensure proper z-index
  },
];
