import { useEffect, useMemo } from 'react';
import { useObserver } from 'mobx-react-lite';
import { uniqBy } from 'lodash';

import VideoCreatorStore from '../../../stores/VideoCreatorStore';
import { BrandingCustomFont } from '../../../types.ts/story';

const LOADED_FONTS = new Set<string>();

const getFontId = (font: BrandingCustomFont) =>
  `${font.fontFamily}-${font.postScriptName}`;

const loadFontFace = async (font: BrandingCustomFont, fontFace: FontFace) => {
  const loadedFont = await fontFace.load();
  LOADED_FONTS.add(getFontId(font));
  document.fonts.add(loadedFont);
};

const useLoadBrandKitFonts = (videoCreator: VideoCreatorStore) => {
  const customFonts = useObserver(() =>
    uniqBy(videoCreator.organization?.branding?.customFonts, (f) =>
      getFontId(f),
    ),
  );

  const fontsToLoad = useMemo(
    () =>
      customFonts
        ?.filter((font) => !LOADED_FONTS.has(getFontId(font)))
        .map((font) => ({
          font,
          fontFace: new FontFace(font.fontFamily, `url(${font.source})`, {
            style: font.style,
            weight: font.usWeightClass.toString(),
          }),
        })),
    [customFonts],
  );

  // Load fonts to the browser
  useEffect(() => {
    if (!fontsToLoad?.length) return;
    fontsToLoad?.forEach(({ font, fontFace }) => {
      loadFontFace(font, fontFace);
    });
  }, [fontsToLoad]);
};

export default useLoadBrandKitFonts;
