import { sortBy, uniq, uniqBy } from 'lodash';
import { BrandingCustomFont } from '@src/types.ts/story';

export enum Bold {
  thin = 'Thin',
  extraLight = 'Extra-Light',
  light = 'Light',
  normal = 'Normal',
  medium = 'Medium',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
  black = 'Black',
}

export const BoldValues = {
  [Bold.thin]: 100,
  [Bold.extraLight]: 200,
  [Bold.light]: 300,
  [Bold.normal]: 400,
  [Bold.medium]: 500,
  [Bold.semiBold]: 600,
  [Bold.bold]: 700,
  [Bold.extraBold]: 800,
  [Bold.black]: 900,
};

export const DEFAULT_BOLD_VALUES: BoldValue[] = [
  { fontSubfamily: Bold.normal, fontWeight: BoldValues.Normal },
  { fontSubfamily: Bold.semiBold, fontWeight: BoldValues['Semi-Bold'] },
  { fontSubfamily: Bold.bold, fontWeight: BoldValues.Bold },
  { fontSubfamily: Bold.extraBold, fontWeight: BoldValues['Extra-Bold'] },
];

type BoldValue = {
  fontSubfamily: string;
  fontWeight: number;
  fontScriptName?: string;
};

export const getCustomFontFromPostScriptName = (
  fontPostScriptName: string,
  fonts: BrandingCustomFont[],
) => {
  const customFonts = uniqBy(fonts, 'postScriptName');
  return customFonts.find((f) => f.postScriptName === fontPostScriptName);
};

export const getBoldValuesFromCustomFonts = (
  fontScriptName: string,
  fonts: BrandingCustomFont[],
) => {
  const fontFamily = fonts.find((f) => f.postScriptName === fontScriptName)
    ?.fontFamily;
  const _fonts = sortBy(
    uniqBy(
      fonts.filter((f) => f.fontFamily === fontFamily && f.style !== 'italic'),
      'fontSubfamily',
    ),
    (f) => f.usWeightClass,
  );
  const { weightsByValue, valuesByWeight } = _fonts.reduce(
    (acc, font) => ({
      weightsByValue: {
        ...acc.weightsByValue,
        [font.fontSubfamily]: font.usWeightClass,
      },
      valuesByWeight: {
        ...acc.valuesByWeight,
        [font.usWeightClass]: font.fontSubfamily,
      },
    }),
    {} as {
      weightsByValue: Record<string, number>;
      valuesByWeight: Record<string, string>;
    },
  );
  return {
    boldValues: uniq(
      _fonts.map((f) => ({
        fontScriptName: f.postScriptName,
        fontSubfamily: f.fontSubfamily,
        fontWeight: f.usWeightClass,
      })),
    ) as BoldValue[],
    boldWeightsByValue: weightsByValue,
    boldValuesByWeight: valuesByWeight,
  };
};

export const findClosestBoldValue = (
  boldValuesByWeight: Record<string, string>,
  target = 400,
) => {
  const closestWeight = Object.keys(boldValuesByWeight)
    .map(Number)
    .reduce(
      (closest, num) =>
        Math.abs(num - target) < Math.abs(closest - target) ? num : closest,
      Number.MAX_SAFE_INTEGER,
    );
  return { weight: closestWeight, value: boldValuesByWeight[closestWeight] };
};
