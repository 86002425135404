import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';

import PropertyDropdown from '../common/PropertyDropdown';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { ExtraElementData } from '@src/types.ts/story';

interface Props {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options?: Array<{ caption: string; value: any }>;
}

const LAYOUT_TYPES = ['stacked-1-1', 'stacked-1-3'];
const NON_LAYOUT_TYPES = ['cover', 'contain', 'fill'];

export const VideoFitPropertySelect: React.FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  let options = props.options || [
      { caption: 'Cover', value: 'cover' },
      { caption: 'Contain', value: 'contain' },
      { caption: 'Fill', value: 'fill' },
      { caption: 'Stacked 1:1', value: 'stacked-1-1' },
      { caption: 'Stacked 1:3', value: 'stacked-1-3' }, 
    ];


  // Check if we're dealing with a dropdown that has both regular fit and layout options
  const hasMixedOptions = options.some(option => LAYOUT_TYPES.includes(option.value)) && 
                          options.some(option => NON_LAYOUT_TYPES.includes(option.value));
  
  // First get default value from element properties
  let value = props.activeElement.source[props.propertyName] ?? props.defaultValue;
  let elementId = props.activeElement.source.id;
  
  // Check if we're dealing with the inner video element
  const isInnerVideoElement = props.activeElement.source.type === 'video' && 
    // Check if contained in a composition
    (() => {
      const renderer = videoCreator.renderer;
      if (renderer && renderer.state) {
        const source = renderer.getSource(renderer.state);
        return source.elements.some((el: any) => 
          el.type === 'composition' && 
          el.elements && 
          Array.isArray(el.elements) && 
          el.elements.some((child: any) => child.id === elementId)
        );
      }
      return false;
    })();
    
  // For inner video elements, just use the direct fit property
  if (isInnerVideoElement && !hasMixedOptions) {
    // Make sure the value is valid for a video element
    if (!NON_LAYOUT_TYPES.includes(value)) {
      value = props.defaultValue;
    }
    return (
      <PropertyDropdown
        value={value}
        onChange={async (newValue) => {
          // Just update the fit property directly
          const modifications = {
            [`${elementId}.${props.propertyName}`]: newValue
          };
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'applying inner video fit',
          );
        }}
        defaultValue={value}
        values={options.map(({ value: optionValue, caption }) => ({ 
          value: optionValue, 
          caption,
          selected: optionValue === value 
        }))}
      />
    );
  }
  
  // For direct stacked layout info from the element itself
  let eed = videoCreator.currentVideo!.extraElementData[elementId] as ExtraElementData | null;
  
  // Check if this is a hidden parent element with a layout
  const isHiddenParentElement = eed && eed.layoutType && LAYOUT_TYPES.includes(eed.layoutType);
  
  if (isHiddenParentElement && eed) {
    // Case 1: This is a hidden parent element with layout info
    value = eed.layoutType;
  } 
  // Case 2: This is a composition element in a layout
  else if (eed && eed.layoutPosition && eed.layoutPosition !== 'hidden' && eed.layoutAssociatedIds) {
    const hiddenElementId = eed.layoutAssociatedIds[0];
    const hiddenEed = videoCreator.currentVideo!.extraElementData[hiddenElementId] as ExtraElementData | null;
    
    if (hiddenEed && hiddenEed.layoutType && LAYOUT_TYPES.includes(hiddenEed.layoutType)) {
      value = hiddenEed.layoutType;
    }
  }
  // Case 3: None of the above special cases, check if the current value is valid
  else {
    // If not a layout or inner video element, and the value isn't valid, use default
    if (!LAYOUT_TYPES.includes(value) && !NON_LAYOUT_TYPES.includes(value)) {
      value = props.defaultValue;
    }
  }
  
  return (
    <PropertyDropdown
      value={ value }
      onChange={async (value) => {
        let elementId = props.activeElement.source.id;
        
        // Check if we're dealing with a child video element inside a composition
        const isInnerVideoElement = props.activeElement.source.type === 'video' && 
          // Check if this element is contained in a composition by checking for parent in renderer
          (() => {
            const renderer = videoCreator.renderer;
            if (renderer && renderer.state) {
              const source = renderer.getSource(renderer.state);
              return source.elements.some((el: any) => 
                el.type === 'composition' && 
                el.elements && 
                Array.isArray(el.elements) && 
                el.elements.some((child: any) => child.id === elementId)
              );
            }
            return false;
          })();
        
        // Special case: if this is an inner video element and we're only changing its fit property
        // Don't do any layout manipulations, just change this element's fit
        if (isInnerVideoElement) {
          const modifications: any = {
            [`${elementId}.${props.propertyName}`]: value
          };
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'applying inner video fit',
          );
          return;
        }
        
        // From here on, we're dealing with either:
        // 1. A composition element (could be part of a layout)
        // 2. A layout parent element (hidden)
        // 3. A standalone video element (not in a composition)
        
        // First, check if this is a child element in a layout composition 
        let isChildElement = false;
        let compositionIds: string[] = [];
        let primaryElementId = elementId;
        
        // Look for compositions that contain this element
        const renderer = videoCreator.renderer;
        if (renderer && renderer.state) {
          const source = renderer.getSource(renderer.state);
          const compositions = source.elements.filter((el: any) => 
            el.type === 'composition' && 
            el.elements && 
            Array.isArray(el.elements) && 
            el.elements.some((child: any) => child.id === elementId)
          );
          
          if (compositions.length > 0) {
            for (const composition of compositions) {
              const compositionEed = videoCreator.currentVideo!.extraElementData[composition.id] as ExtraElementData | null;
              if (compositionEed && compositionEed.layoutPosition && compositionEed.layoutAssociatedIds) {
                // Found a layout composition containing this element
                isChildElement = true;
                compositionIds.push(composition.id);
                primaryElementId = compositionEed.layoutAssociatedIds[0];
                break;
              }
            }
          }
        }
        
        // If this element is a composition in a layout, get its hidden parent element
        let eed = videoCreator.currentVideo!.extraElementData[elementId] as ExtraElementData | null;
        if (eed && eed.layoutPosition && eed.layoutPosition !== 'hidden' && eed.layoutAssociatedIds) {
          elementId = eed.layoutAssociatedIds[0];
          eed = videoCreator.currentVideo!.extraElementData[elementId] as ExtraElementData | null;
        }
        
        // Check if this is a hidden parent element with a layout
        const isHiddenParentElement = eed && eed.layoutType && LAYOUT_TYPES.includes(eed.layoutType);
        
        // Check if we're switching between layout types or to/from layouts
        const isFromLayout = isHiddenParentElement;
        const isToLayout = LAYOUT_TYPES.includes(value as string);
        const isSwitchingLayoutType = isFromLayout && isToLayout && value !== eed?.layoutType;
        const isExitingLayout = isFromLayout && !isToLayout;
        const isEnteringLayout = !isFromLayout && isToLayout;
        
        // Case 1: Switching from one layout type to another layout type
        if (isSwitchingLayoutType) {
          // We need to:
          // 1. Delete all associated elements from the current layout
          // 2. Clear the layout metadata
          // 3. Create new layout elements for the new layout type
          // 4. Keep the element hidden and locked
          
          // First, verify we have associated elements to delete
          if (eed?.layoutAssociatedIds && eed.layoutAssociatedIds.length > 0) {
            // Make a copy of the array to avoid modification during iteration
            const associatedIds = [...eed.layoutAssociatedIds];
            
            // Delete each associated element
            for (const linkedElementId of associatedIds) {
              // Clean up extraElementData for the composition element
              if (videoCreator.currentVideo!.extraElementData[linkedElementId]) {
                delete videoCreator.currentVideo!.extraElementData[linkedElementId];
              }
              
              // Delete the actual element
              await videoCreator.deleteElement(linkedElementId);
            }
          }
          
          // Now clean up the layout metadata
          if (eed) {
            delete eed.layoutAssociatedIds;
            delete eed.layoutPosition;
            delete eed.layoutType;
          }
          
          // Create new layout elements for the new layout type
          await videoCreator.addLayoutElements(elementId, value as string);
          
          // Make sure the element stays hidden and locked
          const modifications = {
            [`${elementId}.visible`]: 'false',
            [`${elementId}.locked`]: 'true',
          };
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'switching layout type',
          );
          
          return;
        }
        
        // Case 2: Exiting a layout (switching from layout to non-layout)
        else if (isExitingLayout) {
          // We need to:
          // 1. Delete all associated elements
          // 2. Clear the layout metadata
          // 3. Make the primary element visible and unlocked
          // 4. Set its fit property
          
          // First, delete associated elements
          if (eed?.layoutAssociatedIds && eed.layoutAssociatedIds.length > 0) {
            // Make a copy to avoid modification during iteration
            const associatedIds = [...eed.layoutAssociatedIds];
            
            // Delete each associated element
            for (const linkedElementId of associatedIds) {
              // Clean up extraElementData
              if (videoCreator.currentVideo!.extraElementData[linkedElementId]) {
                delete videoCreator.currentVideo!.extraElementData[linkedElementId];
              }
              
              // Delete the actual element
              await videoCreator.deleteElement(linkedElementId);
            }
          }
          
          // Clean up the layout metadata
          if (eed) {
            delete eed.layoutAssociatedIds;
            delete eed.layoutPosition;
            delete eed.layoutType;
          }
          
          // Make element visible and set its fit property
          const modifications = {
            [`${elementId}.visible`]: 'true',
            [`${elementId}.locked`]: 'false',
            [`${elementId}.${props.propertyName}`]: value,
            // Also reset position and size
            [`${elementId}.width`]: '100%',
            [`${elementId}.height`]: '100%',
            [`${elementId}.x`]: '50%',
            [`${elementId}.y`]: '50%',
          };
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'exiting layout mode',
          );
          
          return;
        }
        
        // Case 3: Entering layout mode (switching from non-layout to layout)
        else if (isEnteringLayout) {
          // Create new layout elements
          await videoCreator.addLayoutElements(elementId, value as string);
          
          // Make the primary element hidden and locked
          const modifications = {
            [`${elementId}.visible`]: 'false',
            [`${elementId}.locked`]: 'true',
          };
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'entering layout mode',
          );
          
          return;
        }
        
        // Case 4: Just changing the fit property of a non-layout element
        else {
          // Just update the fit property
          const modifications = {
            [`${elementId}.${props.propertyName}`]: value,
          };
          
          // Additional adjustments for aspect ratio if needed
          if (!videoCreator.hasDefaultAspectRatio()) {
            await videoCreator.reframingModeManager.exitReframingMode();
            if (value === 'cover') {
              await videoCreator.adjustModificationsToAspectRatio(modifications, {
                ...videoCreator.getElementSource(props.activeElement),
                [props.propertyName]: value,
              });
            }
          }
          
          await videoCreator.applyVideoStateModifications(
            modifications,
            true,
            'updating fit property',
          );
          
          return;
        }
        
      }}
      defaultValue={value}
      values={options.map(({ value: optionValue, caption }) => ({ 
        value: optionValue, 
        caption,
        // Force dropdown to show correct selection for all non-layout values
        selected: !LAYOUT_TYPES.includes(optionValue) && optionValue === value 
      }))}
    />
  );
});