import { TranscriptElement } from '../types.ts/transcript';
import { StoryVideo, Video } from '../types.ts/story';
import { ClipProducerPreset } from '@src/types.ts/ai_prompts';
import { AIFlowStep } from '../aiFlow/AIFlowStep';
import { toJS } from 'mobx';
import { equalsIgnoringCase, removePunctuation } from '../utility/strings';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { analytics } from '@src/utility/analytics';
import { RootStore } from '@src/stores-v2/RootStore';

export type ClipFragment = {
  title: string;
  text: string;
  duration: number;
  startTime: number;
  src: string;
  platform: string;
  theme: string;
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
};

const getFlowTitleAndCategoryByTheme = (
  clipProducerPresets: ClipProducerPreset[] | null,
  theme: string,
) => {
  return clipProducerPresets?.find((preset) => preset.title === theme)!;
};

export const getDurationBySocialPlatform = (platform: string) => {
  switch (platform) {
    case 'tiktok':
      return [5, 180];
    case 'instagram':
      return [20, 70];
    case 'youtube':
      return [50, 420];
    case 'youtube-shorts':
      return [10, 60];
    case 'facebook':
      return [20, 70];
    case 'twitter':
      return [10, 120];
    case 'linkedin':
      return [10, 120];
    default:
      return [20, 60];
  }
};

const getRangeForDuration = (duration: number) => {
  return (
    {
      15: [6, 29],
      30: [16, 49],
      60: [34, 80],
      90: [65, 120],
      120: [75, 150],
    }[duration] || [0.7 * duration, 1.3 * duration]
  );
};

export const produceClips = async (params: {
  videoCreator: VideoCreatorStore;
  transcriptionText: string;
  originalVideo: StoryVideo;
  originalDuration: number;
  transcriptionElements: TranscriptElement[];
  duration: number | null;
  theme: string;
  customTheme?: boolean;
  platform: string;
  onClipsProduced?: (clips: ClipFragment[]) => void;
  abortToken: { id: string; aborted: boolean };
  rootStore: RootStore;
}) => {
  const {
    videoCreator,
    transcriptionText,
    transcriptionElements,
    duration,
    originalDuration,
    theme,
    customTheme,
    platform,
    originalVideo,
    onClipsProduced,
    abortToken,
    rootStore,
  } = params;

  // analytics
  analytics.track('produce_clips', {
    storyId: videoCreator.story?.id,
    storyTitle: videoCreator.story?.title,
    theme,
    platform,
    duration,
  });

  let [durationMin, durationMax] = getDurationBySocialPlatform(platform);
  // if (theme === 'Highlights') {
  //   [durationMin, durationMax] = [2, 45]; // override for demo
  // }
  if (duration) {
    [durationMin, durationMax] = getRangeForDuration(duration);
  }

  const originalVideoWords = transcriptionText.split(' ').length;
  const newVideoWords = Math.round(
    (originalVideoWords * ((durationMin + durationMax) / 2)) / originalDuration,
  );
  const [clipLengthMin, clipLengthMax] = [durationMin, durationMax].map(
    (duration) =>
      Math.round((originalVideoWords * duration) / originalDuration),
  );

  const preset = customTheme
    ? rootStore.templatedPromptsStore.customClipProducerPreset
    : getFlowTitleAndCategoryByTheme(
        rootStore.templatedPromptsStore.clipProducerPresets,
        theme,
      );
  const clipProducerAiFlow =
    await rootStore.datoClientStore.aiFlowRepository.load(preset);

  if (!clipProducerAiFlow) return [];
  clipProducerAiFlow.context = {
    storyTranscription: transcriptionText,
    storyLength: originalVideoWords,
    storyDuration: originalDuration,
    socialPlatform: platform,
    clipLengthMin,
    clipLengthMax,
    clipLength: newVideoWords,
    clipDurationMin: durationMin,
    clipDurationMax: durationMax,
    clipTheme: theme,
  };

  clipProducerAiFlow.steps[0].stream = 'object';

  clipProducerAiFlow.steps[0].mapper = (
    flowContext: any,
    stepContext: AIFlowStep,
  ): (ClipFragment | null)[] => {
    let prevResponseFragmentsNumber =
      stepContext.context.previousResponseFragmentsNumber || 0;
    let prevResultFragments = (stepContext.context.previousResultFragments ||
      []) as ClipFragment[];

    const resultParts = (stepContext.lastResponse?.slice(
      prevResponseFragmentsNumber,
    ) || []) as { title: string; text_fragment: string }[];

    const NUMBER_OF_WORDS_TO_MATCH = 5;

    if (resultParts.length === 0) {
      if (stepContext.doneStreaming) {
        // reset before possible next retry
        stepContext.context.previousResultFragments = [];
        stepContext.context.previousResponseFragmentsNumber = 0;
      }
      return prevResultFragments;
    }
    stepContext.context.previousResponseFragmentsNumber =
      stepContext.lastResponse?.length || 0;

    const resultFragments: (ClipFragment | null)[] = resultParts.map(
      (part, index: number) => {
        const partIndex = prevResponseFragmentsNumber + index;
        let beginning = stripPunctuationFromEnds(
          part.text_fragment
            .split(' ')
            .slice(0, NUMBER_OF_WORDS_TO_MATCH)
            .join(' '),
        );
        let ending = stripPunctuationFromEnds(
          part.text_fragment
            .split(' ')
            .slice(-NUMBER_OF_WORDS_TO_MATCH)
            .join(' '),
        );

        stepContext.log(
          `Fragment ${partIndex}: \nbeginning: ${beginning}, \nending: ${ending}`,
        );

        const {
          startTime: beginningTime,
          transcriptPosition: beginningTranscriptPosition,
        } = getFragmentPosition(beginning, transcriptionElements) || {};
        if (beginningTime == null || !beginningTranscriptPosition) {
          stepContext.log(
            `Fragment ${partIndex} has no beginning position. Cannot find '${beginning}' in the transcription`,
          );
          return null;
        }

        const {
          endTime: endingTime,
          transcriptPosition: endingTranscriptPosition,
        } = getFragmentPosition(ending, transcriptionElements) || {};

        if (endingTime == null || !endingTranscriptPosition) {
          stepContext.log(
            `Fragment ${partIndex} has no ending position. Cannot find '${ending}' in the transcription`,
          );
          return null;
        }
        const fragmentDuration = endingTime - beginningTime;
        const fragmentWordsCount = part.text_fragment.split(' ').length;

        const duplicateIndexCheck = prevResultFragments.findIndex(
          (fragment) => {
            return (
              fragment.transcriptPosition.startIndex ===
                beginningTranscriptPosition.startIndex &&
              fragment.transcriptPosition.endIndex ===
                endingTranscriptPosition.endIndex
            );
          },
        );
        if (duplicateIndexCheck !== -1) {
          stepContext.log(
            `Fragment ${partIndex} is a duplicate of ${duplicateIndexCheck}`,
          );
          return null;
        }

        if (
          fragmentDuration >= durationMin &&
          fragmentDuration <= durationMax
        ) {
          stepContext.log(
            `Fragment ${partIndex} has a duration of ${fragmentDuration}s, which is within the allowed range; words count: ${fragmentWordsCount}`,
          );
          return {
            title: part.title || '',
            text: part.text_fragment,
            duration: fragmentDuration,
            startTime: beginningTime,
            src:
              originalVideo?.video?.mp4UrlLow ||
              originalVideo?.video?.mp4UrlMedium ||
              originalVideo.url,
            platform,
            theme,
            transcriptPosition: {
              startIndex: beginningTranscriptPosition.startIndex,
              endIndex: Math.min(
                endingTranscriptPosition.endIndex + 1,
                transcriptionElements.length - 1,
              ), // +1 to compensate for stripPunctuationFromEnds
            },
          };
        } else {
          stepContext.log(
            `Fragment ${partIndex} has a duration of ${fragmentDuration}s, which is NOT within the allowed range: ${durationMin} - ${durationMax}; words count: ${fragmentWordsCount}`,
          );
        }
        return null;
      },
    );

    const notNullFragments = resultFragments.filter(
      (part) => !!part,
    ) as ClipFragment[];
    const newResultFragments = prevResultFragments.concat(notNullFragments);
    stepContext.context.previousResultFragments = newResultFragments;

    if (stepContext.doneStreaming) {
      // reset before possible next retry
      stepContext.context.previousResultFragments = [];
      stepContext.context.previousResponseFragmentsNumber = 0;
    }
    return newResultFragments;
  };

  clipProducerAiFlow.steps[0].onPartialResult = (
    result: (ClipFragment | null)[],
  ) => {
    if (abortToken.aborted) {
      throw new Error('Aborted');
    }
    if (result.length > 0) {
      onClipsProduced &&
        onClipsProduced(result.filter((part) => !!part) as ClipFragment[]);
    }
  };
  // debugger;
  await clipProducerAiFlow.run();

  if (abortToken.aborted) {
    throw new Error('Aborted');
  }

  const resultParts = clipProducerAiFlow.result.filter(
    (part: ClipFragment | null) => !!part,
  );

  return resultParts;
};

function getFragmentPosition(
  fragment: string,
  transcriptElements?: TranscriptElement[],
): {
  transcriptPosition: {
    startIndex: number;
    endIndex: number;
  };
  duration: number;
  startTime: number;
  endTime: number;
} | null {
  let targetSentence = removePunctuation(fragment);
  // loop through all transcript elements and track the index of the target sentence across multiple elements
  if (!transcriptElements || !targetSentence) return null;

  let targetSentenceCurrIndex = 0;
  let currElementIndex = 0;
  let targetSentenceElementStartIndex = 0;
  let targetSentenceElementEndIndex = 0;
  let found = false;

  while (currElementIndex < (transcriptElements?.length - 1 || 0)) {
    const currentElement = transcriptElements[currElementIndex];
    const currElementValue = currentElement.value;

    // if element contains the start of the target sentence save it as the start of the index
    if (
      currElementValue &&
      currentElement?.state !== 'removed' &&
      currentElement?.state !== 'cut'
    ) {
      // if currElementValue is punctuation skip
      if (currElementValue && currElementValue.match(/^[.,;!?]$/)) {
        currElementIndex++;
        continue;
      } else if (
        equalsIgnoringCase(
          targetSentence.substring(
            targetSentenceCurrIndex,
            targetSentenceCurrIndex + currElementValue.length,
          ),
          currElementValue,
        )
      ) {
        if (targetSentenceCurrIndex === 0) {
          targetSentenceElementStartIndex = currElementIndex;
        }
        targetSentenceCurrIndex += currElementValue.length;
      } else {
        targetSentenceCurrIndex = 0;
      }
    }
    if (targetSentenceCurrIndex === targetSentence.length) {
      targetSentenceElementEndIndex = currElementIndex;
      found = true;
      break;
    }
    currElementIndex++;
  }

  // RETURN if not found
  if (!found) return null;

  let ts = null;
  let end_ts = null;

  // loop through all transcript elements and track the index of the target sentence across multiple elements
  currElementIndex = targetSentenceElementStartIndex;
  while (currElementIndex <= targetSentenceElementEndIndex) {
    const currElementFinal = transcriptElements[currElementIndex];

    if (currElementFinal) {
      if (ts === null && currElementFinal.ts != null) {
        ts = currElementFinal.ts - (currElementFinal.buffer_before_ts || 0);
      }
      if (currElementFinal.end_ts) {
        end_ts =
          currElementFinal.end_ts + (currElementFinal.buffer_after_ts || 0);
      }
    }
    currElementIndex++;
  }

  if (ts == null || end_ts == null) return null;

  return {
    transcriptPosition: {
      startIndex: targetSentenceElementStartIndex,
      endIndex: targetSentenceElementEndIndex,
    },
    duration: end_ts - ts,
    startTime: ts,
    endTime: end_ts,
  };
}

function stripPunctuationFromEnds(text: string) {
  return text.replaceAll(
    /^[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+|[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/g,
    '',
  );
}

export async function saveProducedClips(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
  sourcePlatform: 'creator-studio' | 'content-studio',
): Promise<void> {
  const originalSource = getOriginalSource(videoCreator);
  const originalVideo = getOriginalVideo(videoCreator);
  if (sourcePlatform === 'creator-studio') {
    // same source should be used for all produced clips
    for (const clip of clips) {
      await saveClipInCreator(
        videoCreator,
        clip,
        originalVideo,
        originalSource,
      );
    }
  } else {
    for (const clip of clips) {
      await videoCreator.createNewVideoForContentClip(originalVideo, clip);

      copyClipToCurrentVideo(videoCreator, clip);
      await videoCreator.saveStoryAndVideo(false, false);
    }
  }
}

export async function saveClipFromTranscript(
  videoCreator: VideoCreatorStore,
  clip: any,
) {
  await videoCreator.saveStoryAndVideo();
  const originalVideo = videoCreator.currentVideo;
  const originalSource = videoCreator.renderer?.getSource();
  if (!originalSource || !originalVideo) {
    throw new Error('No video source found to create clip');
  }
  await saveClipInCreator(videoCreator, clip, originalVideo, originalSource);
}

export async function saveClipInCreator(
  videoCreator: VideoCreatorStore,
  clip: any,
  originalVideo: Video,
  originalSource: any,
) {
  const newTitle = clip.title || `${originalVideo.title} - ${clip.theme}`;
  await videoCreator.createNewVideoFromSource(
    newTitle,
    originalSource,
    originalVideo.transcriptionChanges,
    originalVideo.transcriptionSnapshot,
    originalVideo.extraElementData,
    'creator-studio',
  );

  await videoCreator.cropTranscriptAndVideoTo(
    clip.transcriptPosition.startIndex,
    clip.transcriptPosition.endIndex,
  );

  await videoCreator.setDuration(null);
  copyClipToCurrentVideo(videoCreator, clip);
  await videoCreator.saveStoryAndVideo();
}

function copyClipToCurrentVideo(
  videoCreator: VideoCreatorStore,
  clip: ClipFragment,
) {
  videoCreator.currentVideo!.clipJson = {
    startTime: clip.startTime,
    duration: clip.duration,
  };
}

function getOriginalSource(
  videoCreator: VideoCreatorStore,
): Record<string, any> {
  let originalSource = videoCreator.renderer?.getSource();
  if (!originalSource) {
    originalSource = videoCreator.currentVideo?.videoSource;
  }
  if (!originalSource) {
    throw new Error('No video source found');
  }
  return originalSource;
}

function getOriginalVideo(videoCreator: VideoCreatorStore): Video {
  return toJS(videoCreator.currentVideo!);
}

function getOriginalDuration(videoCreator: VideoCreatorStore): number {
  return videoCreator.duration;
}

export async function saveClipsAsReel(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
  sourcePlatform: 'creator-studio' | 'content-studio',
): Promise<void> {
  if (sourcePlatform === 'creator-studio') {
    await saveClipsAsReelInCreator(videoCreator, clips);
  } else {
    await saveClipsAsReelInContentStudio(videoCreator, clips);
  }
}

async function saveClipsAsReelInCreator(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
) {
  const originalSource = getOriginalSource(videoCreator);
  const originalVideo = getOriginalVideo(videoCreator);
  const newTitle = getReelTitle(videoCreator);

  await videoCreator.createNewVideoFromSource(
    newTitle,
    originalSource,
    originalVideo.transcriptionChanges,
    originalVideo.transcriptionSnapshot,
    originalVideo.extraElementData,
    'creator-studio',
  );

  await videoCreator.cropTranscriptAndVideoInMultiplePlaces(
    clipsToRanges(clips),
  );

  await videoCreator.setDuration(null);
  copyClipToCurrentVideo(videoCreator, combineClipsIntoOne(clips, newTitle));
  await videoCreator.saveStoryAndVideo();
}

async function saveClipsAsReelInContentStudio(
  videoCreator: VideoCreatorStore,
  clips: ClipFragment[],
) {
  const originalVideo = getOriginalVideo(videoCreator);
  const newTitle = getReelTitle(videoCreator);
  const combinedClip = combineClipsIntoOne(clips, newTitle);

  await videoCreator.createNewVideoForContentClipsReel(
    originalVideo,
    combinedClip,
    clipsToRanges(clips),
  );

  copyClipToCurrentVideo(videoCreator, combinedClip);
  await videoCreator.saveStoryAndVideo(false, false);
}

function getReelTitle(videoCreator: VideoCreatorStore): string {
  return `${videoCreator.story!.title} - Compilation`;
}

function combineClipsIntoOne(
  clips: ClipFragment[],
  newTitle: string,
): ClipFragment {
  return clips.reduce(
    (acc, clip) => {
      acc.text += clip.text + '\n';
      acc.duration += clip.duration;
      acc.startTime = Math.min(acc.startTime, clip.startTime);
      acc.src = clip.src;
      acc.platform = clip.platform;
      acc.theme = clip.theme;
      acc.transcriptPosition = {
        startIndex: Math.min(
          acc?.transcriptPosition?.startIndex,
          clip.transcriptPosition.startIndex,
        ),
        endIndex: Math.max(
          acc?.transcriptPosition?.endIndex,
          clip.transcriptPosition.endIndex,
        ),
      };
      acc.title = newTitle;
      return acc;
    },
    {
      text: '',
      duration: 0,
      startTime: Infinity,
      transcriptPosition: {
        startIndex: Infinity,
        endIndex: -1,
      },
    } as ClipFragment,
  );
}

function clipsToRanges(
  clips: ClipFragment[],
): { fromElement: number; toElement: number }[] {
  return clips.map((clip) => ({
    fromElement: clip.transcriptPosition.startIndex,
    toElement: clip.transcriptPosition.endIndex,
  }));
}
