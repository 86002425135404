import axios from 'axios';

const API_ORIGIN = process.env.REACT_APP_API_URL;

export async function uploadFileToS3ExternalUploads(
  file: File,
  organization_id: string | null | undefined,
  options?: {
    onUploadProgress?: (progress: number, done: boolean) => void;
  },
): Promise<string> {
  const response = await fetch(`${API_ORIGIN}/api/aws-presigned-url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: getExternalUploadsBucketPathKey(file.name, organization_id),
    }),
  });
  const { signedPutUrl, signedGetUrl } = await response.json();

  await axios.put(signedPutUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent: any) => {
      if (options?.onUploadProgress && progressEvent?.total) {
        const progress = (progressEvent.loaded * 100) / progressEvent.total;
        const done = progressEvent.loaded === progressEvent.total;
        options.onUploadProgress(progress, done);
      }
    },
  });

  return signedGetUrl;
}

export function renameFile(file: File, newBasename: string): File {
  const fileExtension = file.name.split('.').slice(-1)[0];
  const newName = `${newBasename}.${fileExtension}`;

  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export function getExternalUploadsBucketPathKey(
  fileKey: string,
  organization_id: string | null | undefined,
): string {
  return `externalUploads/${organization_id || 'no_org'}/${fileKey}`;
}
