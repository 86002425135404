import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { VideoPreset } from './VideoPreset';
import { PropertyCaption } from './PropertyCaption';
import { VideoFitPropertySelect } from './VideoFitPropertySelect';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import { PropertyUnitInput } from './PropertyUnitInput';

import styled from 'styled-components';
import { ElementAnimationSettings } from './ElementAnimationSettings';
import { numericalInputStyles } from '../../styles/mainStyle';
import { GenericAnimationSettings } from './GenericAnimationSettings';
import FadeProducer from '../../fadeEffectProcessor/FadeProducer';
import { FIT_DEFAULT } from '../../config/videoSettings';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { ExtraElementData } from '@src/types.ts/story';
import lodash from 'lodash';
import SliderSelect from '../common/PlainSlider';
import ScaleIcon from '@src/svgs/ScaleIcon';
import UpDownIcon from '@src/svgs/UpDownIcon';
import LeftRightIcon from '@src/svgs/LeftRightIcon';

interface VideoSettingsProps {
  activeElement: ElementState;
}

export const VideoSettings: React.FC<VideoSettingsProps> = (props) => {
  const videoCreator = useVideoCreatorStore();
  const [hiddenParentElement, setHiddenParentElement] = useState<ElementState | null>(null);
  const [isLayoutComposition, setIsLayoutComposition] = useState<boolean>(false);
  const [childVideoElement, setChildVideoElement] = useState<ElementState | null>(null);

  const isBroll = !videoCreator.isOriginalVideoElement(
    props.activeElement.source,
  );
  const fadeProducer = new FadeProducer(videoCreator, props.activeElement);
  
  // Keep track of slider values to prevent UI from snapping
  const [sliderValues, setSliderValues] = useState({
    x: 50,
    y: 50,
  });
  
  // Function to update slider values from the current element properties
  // Using a function in the setState to avoid dependency on sliderValues
  const updateSliderValuesFromElement = useCallback(() => {
    const element = childVideoElement || props.activeElement;
    if (element && element.source) {
      setSliderValues(prevValues => {
        const newValues = { ...prevValues };
        
        // For X alignment, prefer x_alignment if available, then x
        if (element.source.x_alignment !== undefined) {
          const xString = element.source.x_alignment.toString();
          const xVal = parseFloat(xString);
          if (!isNaN(xVal)) newValues.x = xVal;
        } else if (element.source.x !== undefined) {
          const xString = element.source.x.toString();
          const xVal = parseFloat(xString);
          if (!isNaN(xVal)) newValues.x = xVal;
        } else {
          newValues.x = 50; // Default value
        }
        
        // For Y alignment, prefer y_alignment if available, then y
        if (element.source.y_alignment !== undefined) {
          const yString = element.source.y_alignment.toString();
          const yVal = parseFloat(yString);
          if (!isNaN(yVal)) newValues.y = yVal;
        } else if (element.source.y !== undefined) {
          const yString = element.source.y.toString();
          const yVal = parseFloat(yString);
          if (!isNaN(yVal)) newValues.y = yVal;
        } else {
          newValues.y = 50; // Default value
        }
        
        return newValues;
      });
    }
  }, [childVideoElement, props.activeElement]);
  
  // Update slider values when the active element changes
  useEffect(() => {
    updateSliderValuesFromElement();
  }, [props.activeElement, updateSliderValuesFromElement]);
  
  // Update slider values when the child video element changes
  useEffect(() => {
    if (childVideoElement) {
      updateSliderValuesFromElement();
    }
  }, [childVideoElement, updateSliderValuesFromElement]);
  
  // Force update slider values when active element source changes 
  // This ensures sliders update when switching between compositions in stacked layouts
  useEffect(() => {
    updateSliderValuesFromElement();
  }, [props.activeElement.source.id, childVideoElement?.source?.id, updateSliderValuesFromElement]);
  
  // Modified function to immediately update the UI and then debounce the API call
  const modifyPropertyWithSlider = (propertyName: string, newValue: number) => {
    // Update local state immediately for smooth UI
    if (propertyName === 'x') {
      setSliderValues(prev => ({...prev, x: newValue}));
    } else if (propertyName === 'y') {
      setSliderValues(prev => ({...prev, y: newValue}));
    }
    
    // Convert to percentage string (without spaces)
    const propertyValue = `${newValue}%`;
    
    // Debounce the actual API call
    modifyPropertyDebounced(propertyName, propertyValue);
  };
  
  const modifyPropertyDebounced = lodash.debounce(
    async (propertyName: string, propertyValue: any) => {
      const actionLabel = `changing video ${propertyName}`;
      const elementToModify = childVideoElement || props.activeElement;
      const modifications: Record<string, string> = {};
      
      // If changing x or y alignment, also update the related properties to match
      if (propertyName === 'x') {
        // Set all related x-position values with percentage strings to ensure they stay in sync
        modifications[`${elementToModify?.source.id}.x_anchor`] = propertyValue;
        modifications[`${elementToModify?.source.id}.x`] = propertyValue;
        modifications[`${elementToModify?.source.id}.x_alignment`] = propertyValue;
      } else if (propertyName === 'y') {
        // Set all related y-position values with percentage strings to ensure they stay in sync
        modifications[`${elementToModify?.source.id}.y_anchor`] = propertyValue;
        modifications[`${elementToModify?.source.id}.y`] = propertyValue;
        modifications[`${elementToModify?.source.id}.y_alignment`] = propertyValue;
      } else {
        // For other properties that don't need syncing
        modifications[`${elementToModify?.source.id}.${propertyName}`] = propertyValue;
      }
      
      await videoCreator.applyVideoStateModifications(
        modifications,
        true,
        actionLabel,
      );
    },
    250,
  );

  const handleChangeScale = lodash.debounce(async (value: number) => {
    const actionLabel = 'changing video scale';
    const scale = value + '%';
    const elementToModify = childVideoElement || props.activeElement;
    await videoCreator.applyVideoStateModifications(
      {
        [`${elementToModify?.source.id}.${'width'}`]: scale,
        [`${elementToModify?.source.id}.${'height'}`]: scale,
      },
      true,
      actionLabel,
    );
  }, 250);

  useEffect(() => {
    // Check if current element is a composition element part of a layout
    const elementId = props.activeElement.source.id;
    const eed = videoCreator.currentVideo?.extraElementData[elementId] as ExtraElementData | null;
    
    // Handle composition elements
    if (props.activeElement.source.type === 'composition') {
      // Find the child video element inside this composition
      if (props.activeElement.elements && props.activeElement.elements.length > 0) {
        const videoChild = props.activeElement.elements.find(e => e.source.type === 'video');
        if (videoChild) {
          setChildVideoElement(videoChild);
        }
      }
      
      // Check if it's a layout composition
      if (eed && eed.layoutPosition && eed.layoutPosition !== 'hidden' && eed.layoutAssociatedIds && eed.layoutAssociatedIds.length > 0) {
        setIsLayoutComposition(true);
        
        // This is a layout composition (top or bottom), get the hidden parent element
        const hiddenParentId = eed.layoutAssociatedIds[0];
        const hiddenParent = videoCreator.renderer?.state?.elements.find(e => e.source.id === hiddenParentId);
        
        if (hiddenParent) {
          setHiddenParentElement(hiddenParent);
        }
      } else {
        setIsLayoutComposition(false);
        setHiddenParentElement(null);
      }
    } else {
      // Handle direct video elements (not compositions)
      setIsLayoutComposition(false);
      setHiddenParentElement(null);
      setChildVideoElement(null);
    }
  }, [props.activeElement, videoCreator.currentVideo?.extraElementData]);

  if (!props.activeElement) {
    return null;
  }

  // Determine which element to use for different properties
  const displayElement = childVideoElement || props.activeElement;
  
  // Update slider values directly based on current state
  // Do this in render to ensure it's always up-to-date
  const activeElementForSliders = childVideoElement || props.activeElement;
  
  // Set x value directly from the source
  const xValue = (() => {
    if (!activeElementForSliders?.source) return 50;
    
    // Try x_alignment first
    if (activeElementForSliders.source.x_alignment !== undefined) {
      const val = parseFloat(activeElementForSliders.source.x_alignment.toString());
      if (!isNaN(val)) return val;
    }
    
    // Then try x
    if (activeElementForSliders.source.x !== undefined) {
      const val = parseFloat(activeElementForSliders.source.x.toString());
      if (!isNaN(val)) return val;
    }
    
    return 50; // Default
  })();
  
  // Set y value directly from the source
  const yValue = (() => {
    if (!activeElementForSliders?.source) return 50;
    
    // Try y_alignment first
    if (activeElementForSliders.source.y_alignment !== undefined) {
      const val = parseFloat(activeElementForSliders.source.y_alignment.toString());
      if (!isNaN(val)) return val;
    }
    
    // Then try y
    if (activeElementForSliders.source.y !== undefined) {
      const val = parseFloat(activeElementForSliders.source.y.toString());
      if (!isNaN(val)) return val;
    }
    
    return 50; // Default
  })();
  const sourceUrl = displayElement.source.source;
  
  return (
    <Fragment>
      <VideoPreset
        activeElement={displayElement}
        url={sourceUrl}
      />

      <ElementAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                parseFloat(value),
                props.activeElement.duration,
              );
              await videoCreator.applyVideoStateModifications(
                {
                  ...newVideoOverlays,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                },
                true,
                'changing video placement',
              );
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                props.activeElement.source.time,
                parseFloat(value),
              );
              await videoCreator.applyVideoStateModifications(
                {
                  ...newVideoOverlays,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                },
                true,
                'changing video placement',
              );
            }}
          />
        </Item>

        {/* Layout/Fit dropdown - show all options */}
        <Item>
          <PropertyCaption>
            {isLayoutComposition ? 'Layout Type' : 'Fit'}
          </PropertyCaption>
          <VideoFitPropertySelect
            activeElement={isLayoutComposition && hiddenParentElement ? hiddenParentElement : props.activeElement}
            propertyName="fit"
            defaultValue={isLayoutComposition ? "stacked-1-1" : FIT_DEFAULT}
            options={[
              { caption: 'Cover', value: 'cover' },
              { caption: 'Contain', value: 'contain' },
              { caption: 'Fill', value: 'fill' },
              { caption: 'Stacked 1:1', value: 'stacked-1-1' },
              { caption: 'Stacked 1:3', value: 'stacked-1-3' },
            ]}
          />
        </Item>
        
        {/* Add a second fit dropdown for the child video element when we're inside a composition */}
        {childVideoElement && (
          <Item>
            <PropertyCaption>Inner Video Fit</PropertyCaption>
            <VideoFitPropertySelect
              activeElement={childVideoElement}
              propertyName="fit"
              defaultValue={FIT_DEFAULT}
              options={[
                { caption: 'Cover', value: 'cover' },
                { caption: 'Contain', value: 'contain' },
                { caption: 'Fill', value: 'fill' },
              ]}
            />
          </Item>
        )}
        
        {isBroll && (
          <Item>
            <PropertyCaption>Volume</PropertyCaption>
            <PropertyNumericalInput
              activeElement={props.activeElement}
              propertyName="volume"
              defaultValue="100"
              unit="%"
              customStyles={numericalInputStyles}
            />
          </Item>
        )}
      </GroupContent>

      <GenericAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Audio Fade In</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_in"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Audio Fade Out</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_out"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
      </GroupContent>
      <GroupContent>
        <Item>
          <PropertyCaption>X-Alignment</PropertyCaption>
          <SliderAndIcon>
            <LeftRightIcon />
            <SliderSelect
              getValue={(newValue) => modifyPropertyWithSlider('x', newValue)}
              defaultValue={50}
              currValue={xValue}
              min={0}
              max={100}
            />
          </SliderAndIcon>
        </Item>
        <Item>
          <PropertyCaption>Y-Alignment</PropertyCaption>
          <SliderAndIcon>
            <UpDownIcon />
            <SliderSelect
              getValue={(newValue) => modifyPropertyWithSlider('y', newValue)}
              defaultValue={50}
              currValue={yValue}
              min={0}
              max={100}
            />
          </SliderAndIcon>
        </Item>
        <Item>
          <PropertyCaption>Scale</PropertyCaption>
          <SliderAndIcon>
            <ScaleIcon />
            <SliderSelect
              getValue={(newValue) => handleChangeScale(newValue)}
              defaultValue={100}
              currValue={(() => {
                const element = childVideoElement || props.activeElement;
                if (element && element.source && element.source.width !== undefined) {
                  const width = element.source.width.toString();
                  const widthVal = parseFloat(width);
                  return !isNaN(widthVal) ? widthVal : 100;
                }
                return 100;
              })()}
              min={100}
              max={300}
            />
          </SliderAndIcon>
        </Item>
        
        {/* Keep the original input fields for precise control */}
        {/* <Item>
          <PropertyCaption>X Position (precise)</PropertyCaption>
          <PropertyUnitInput
            activeElement={props.activeElement}
            propertyName="x"
            defaultValue="50%"
            unit="%"
            passUnitToCreatomate={true}
            allowedUnits={["%", "px"]}
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Y Position (precise)</PropertyCaption>
          <PropertyUnitInput
            activeElement={props.activeElement}
            propertyName="y"
            defaultValue="50%"
            unit="%"
            passUnitToCreatomate={true}
            allowedUnits={["%", "px"]}
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Width (precise)</PropertyCaption>
          <PropertyUnitInput
            activeElement={props.activeElement}
            propertyName="width"
            defaultValue="100%"
            unit="%"
            passUnitToCreatomate={true}
            allowedUnits={["%", "px"]}
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Height (precise)</PropertyCaption>
          <PropertyUnitInput
            activeElement={props.activeElement}
            propertyName="height"
            defaultValue="100%"
            unit="%"
            passUnitToCreatomate={true}
            allowedUnits={["%", "px"]}
            customStyles={numericalInputStyles}
          />
        </Item> */}
      </GroupContent>
    </Fragment>
  );
};

const GroupContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const Item = styled.div``;

const SliderAndIcon = styled.div`
  display: flex;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 12px;
  gap: 5px;
  align-items: center;
  height: 15px;
`;
