import { toPng } from 'html-to-image';

import { BlogOrEmailContent, MediaSubType, Studio } from '../types.ts/general';
import {
  Artifact,
  Caption,
  ContentViewData,
  FileData,
  PhotoArtifactTab,
  PhotoAsset,
  PhotoTab,
  Story,
  VideoClip,
} from '../types.ts/story';
import TurndownService from 'turndown';
import { v4 as uuid } from 'uuid';
import { AssetRepository } from '../repositories/AssetRepository';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { AspectRatio } from '../types.ts/video';
import VideoCreatorStore from '../stores/VideoCreatorStore';
import { AlbumRepository, StoryRepository } from '../repositories';

const turndownService = new TurndownService();

export function delay(t: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, t);
  });
}

export function retry<T>(
  fn: () => Promise<T>,
  retries = 3,
  delayTime = 2000,
  err?: Error,
): Promise<T> {
  if (!retries) {
    return Promise.reject(err);
  }

  return delay(delayTime).then(() =>
    fn().catch((err: Error) => {
      return retry(fn, retries - 1, delayTime * 2, err);
    }),
  );
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize),
  );
}

export function randomString(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const handleCopyToClipboard = async (text: string, format = 'plain') => {
  try {
    const clipboardItem = new ClipboardItem({
      [`text/${format}`]: new Blob([text], { type: `text/${format}` }),
    });

    await navigator.clipboard.write([clipboardItem]);
  } catch (err) {}
};

export function getRandomFileName(inputString: string) {
  if (!inputString) return '';
  const words = inputString.split(/\s+/);
  const filteredWords = words.filter((word) => word.length > 4);

  const numberOfWords = filteredWords.length >= 3 ? 3 : 2;

  if (filteredWords.length < numberOfWords) {
    return inputString;
  }

  const randomIndices: number[] = [];

  while (randomIndices.length < numberOfWords) {
    const randomIndex = Math.floor(Math.random() * filteredWords.length);

    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }

  const randomWords = randomIndices.map((index) => filteredWords[index]);
  const randomNumber = Math.floor(Math.random() * 1000);

  return `${randomWords.join('_')}_${randomNumber}`;
}

export async function handleDownloadMedia<
  T extends { url: string; fileName?: string; id?: string },
  E extends HTMLElement,
>(e: React.MouseEvent<E, MouseEvent>, media: T) {
  e.preventDefault();
  e.stopPropagation();
  try {
    const url = media.url;
    const fileName = media.fileName;
    const link = document.createElement('a');
    let blob = await fetch(url).then((r) => r.blob());

    link.href = globalThis.URL.createObjectURL(blob);
    link.download = fileName || media?.id || '';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
}

export const getPosterUrl = (
  url: string,
  width: number,
  height: number,
  fit = true,
) => {
  if (!url) return;

  const imgixParts = {
    w: width,
    h: height,
    ...(fit && { fit: 'crop' }),
    q: 95,
    // fit: 'fill',
    // fill: 'blur',
  };
  return (
    url +
    '?' +
    Object.entries(imgixParts)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
  );
};

export const shouldWhitelist = (
  flags: (boolean | string)[],
  enterprisePlatform: string[],
) => {
  //const showcases = videoCreator.story?._allReferencingShowcases;

  //const showcaseIds = showcases?.map((s) => s.id) || [];
  return flags?.some((s) => s) || enterprisePlatform?.some((s) => s) || false;
};
export async function handleRemoveMedia<
  T extends PhotoArtifactTab | Omit<MediaSubType, MediaSubType.all>,
>(
  videoCreator: VideoCreatorStore,
  albumRepository: AlbumRepository,
  mediaId: string,
  type: T,
  toggleDropdown = (e: boolean) => {},
) {
  try {
    let artifacts = videoCreator.story?.storyArtifacts || [];
    let assets = videoCreator.story?.storyAssets || [];
    let aiPhotos = videoCreator.story?.aiPhotos || [];
    let videoArtifacts = videoCreator.story?.storyArtifactsVideo || [];
    let albumIndex;

    let resource;

    const narrowedType = type as unknown as PhotoArtifactTab | MediaSubType;

    switch (narrowedType) {
      case PhotoArtifactTab.story:
      case MediaSubType.artifact:
        artifacts = artifacts.filter((artifact) => artifact.id !== mediaId);
        resource = artifacts;
        break;
      case PhotoArtifactTab.album:
        const albumIdx =
          videoCreator.story?._allReferencingShowcases?.findIndex(
            (a) => a.organizationArtifacts?.some((v) => v.id === mediaId),
          );
        if (albumIdx !== undefined && albumIdx > -1) {
          videoCreator.story!._allReferencingShowcases[
            albumIdx
          ].organizationArtifacts =
            videoCreator.story!._allReferencingShowcases[
              albumIdx
            ].organizationArtifacts?.filter((a) => a.id !== mediaId) || [];
          albumIndex = albumIdx;
        }
        resource =
          videoCreator?.story?._allReferencingShowcases?.flatMap(
            (album) => album.organizationArtifacts || [],
          ) || [];
        break;
      case PhotoArtifactTab.logo: {
        const albumIdx =
          videoCreator.story?._allReferencingShowcases?.findIndex(
            (a) => a.organizationLogos?.some((v) => v.id === mediaId),
          );
        if (albumIdx !== undefined && albumIdx > -1) {
          videoCreator.story!._allReferencingShowcases[
            albumIdx
          ].organizationLogos =
            videoCreator.story!._allReferencingShowcases[
              albumIdx
            ].organizationLogos?.filter((a) => a.id !== mediaId) || [];
          albumIndex = albumIdx;
        }
        resource =
          videoCreator?.story?._allReferencingShowcases?.flatMap(
            (album) => album.organizationLogos || [],
          ) || [];
        break;
      }
      case PhotoArtifactTab.stock:
      case MediaSubType.stock:
        assets = assets.filter((asset) => asset.id !== mediaId);
        resource = assets;
        break;
      case MediaSubType.ai:
      case PhotoArtifactTab.ai:
        aiPhotos = aiPhotos.filter((ai) => ai.id !== mediaId);
        resource = aiPhotos;
        break;
      case MediaSubType.video:
        videoArtifacts = videoArtifacts.filter((vi) => vi.id !== mediaId);
        resource = videoArtifacts;
        break;
      default:
        return;
    }

    videoCreator.story! = {
      ...videoCreator.story!,
      storyArtifacts: artifacts,
      storyAssets: assets,
      storyArtifactsVideo: videoArtifacts,
      aiPhotos,
    };

    const photoResource = resource
      ?.filter((a) => a.responsiveImage)
      .map((a) => ({
        id: a.id,
        title: a.title,
        src: a.responsiveImage?.src,
        type: 'artifact',
        ...a.customData,
      })) as PhotoAsset[];

    toggleDropdown(false);
    videoCreator.selectedPhotoAssets = {
      tab: type as PhotoArtifactTab,
      resource: photoResource,
      lastSelectedStock: videoCreator.selectedPhotoAssets.lastSelectedStock,
      lastSelectedAi: videoCreator.selectedPhotoAssets.lastSelectedAi,
      selectedId: videoCreator.selectedPhotoAssets.selectedId,
    };
    if (type === PhotoArtifactTab.album || type === PhotoArtifactTab.logo) {
      if (albumIndex !== undefined && albumIndex > -1) {
        await albumRepository?.update(
          videoCreator.story!._allReferencingShowcases[albumIndex]!,
        );
      }
    } else {
      await videoCreator.updateStory(videoCreator.story!);
    }
  } catch (error) {
  } finally {
    toggleDropdown(false);
  }
}

export const handleLoadBlogContent = (
  videoCreator: VideoCreatorStore,
  content: BlogOrEmailContent,
  setCurrentContent: (content: BlogOrEmailContent | null) => void,
) => {
  setCurrentContent(content);
  let markdownContent = turndownService.turndown(content.content);
  const text = markdownContent
    .replace(/Replace image\n\nDelete image\n\n/g, '')
    .replace(/Add Photo\n\nDelete\n\n/g, '');

  const generatedContent = videoCreator.contentStudioGeneratedContent;
  videoCreator.contentStudioGeneratedContent = {
    ...(generatedContent || {}),
    Blog: {
      ...(generatedContent?.Blog || {}),
      content: {
        ...(generatedContent?.Blog?.content || {}),
        response: text,
      },
    },
  } as ContentViewData;

  videoCreator.selectedBlogContent = {
    id: content.id.toString(),
    content: content.title,
    type: 'saved',
  };
};

export async function handleDeleteBlogContent(
  videoCreator: VideoCreatorStore,
  storyRepository: StoryRepository,
  contentId: number,
  currentContentId: number | undefined,
  setOptionsModal: (e: null) => void,
  setDropdownOpen: (e: boolean) => void,
  setCurrentContent: (e: BlogOrEmailContent | null) => void,
  type: 'savedBlog' | 'savedEmail' = 'savedBlog',
  saveType: 'saved_blog' | 'saved_email' = 'saved_blog',
) {
  setOptionsModal(null);
  setDropdownOpen(false);
  videoCreator.selectedBlogContent = null;

  const content = videoCreator.story?.[type];
  if (!content?.hasOwnProperty(contentId)) return;
  delete videoCreator.story?.savedBlog?.[contentId];
  if (contentId === currentContentId) {
    videoCreator.selectedBlogContent = null;
    setCurrentContent(null);
  }
  await storyRepository?.handleSaveAiGeneratedContent(
    videoCreator.story!.id,
    videoCreator.story?.[type]!,
    saveType,
  );
}

export async function handleRenameBlogContent(
  videoCreator: VideoCreatorStore,
  storyRepository: StoryRepository,
  contentId: number,
  title: string,
  type: 'savedBlog' | 'savedEmail',
  saveType: 'saved_blog' | 'saved_email',
  toggleContentToEditModal: (e: null) => void,
  setOptionsModal: (e: null) => void,
  setDropdownOpen: (e: boolean) => void,
  setCurrentContent: (e: BlogOrEmailContent | null) => void,
  loadContent: boolean = true,
) {
  toggleContentToEditModal(null);
  setOptionsModal(null);
  setDropdownOpen(false);

  // Need to use any type since the exact shape is not fully known
  const data = videoCreator.story![type] as any;
  if (!data || !Object.keys(data).length) return;

  if (data.hasOwnProperty(contentId.toString())) {
    data[contentId].title = title;
  } else {
    // Safe mutation of the data
    data[contentId] = {
      ...(data[contentId] || { content: '' }),
      title,
    };
  }

  if (loadContent) {
    handleLoadBlogContent(
      videoCreator,
      {
        id: contentId,
        title: title,
        content: data[contentId].content,
        username: data[contentId].username,
      },
      setCurrentContent,
    );
  }

  await storyRepository?.handleSaveAiGeneratedContent(
    videoCreator.story!.id,
    videoCreator.story?.[type]!,
    saveType,
  );
}

export const getCurrentStudio = (pathname: string) => {
  switch (pathname.split('/').join('')) {
    case Studio.content:
      return Studio.content;
    case Studio.creator:
      return Studio.creator;
    case Studio.dashboard:
      return Studio.dashboard;
    case Studio.brandKit:
      return Studio.brandKit;
    default:
      return null;
  }
};

export const initializeContentStudioContent = (
  videoCreator: VideoCreatorStore,
  storyId: string | undefined,
  title: 'Blog' | 'Quotes' | 'Email',
) => {
  if (!storyId) return;
  const contentGenerated = videoCreator.contentStudioGeneratedContent;
  if (contentGenerated?.[title]?.storyId === storyId) return;
  videoCreator.contentStudioGeneratedContent = {
    ...(contentGenerated || {}),
    [title]: {
      storyId,
      hasBeenGenerated: false,
      content: { title, response: '' },
    },
  } as ContentViewData;
};

export const getAvailableClipFormats = (video: VideoClip) => {
  const formats: string[] =
    [video, ...(video.associatedVideos || [])]
      .filter((v) => v.videoFilePrimary)
      .map((v) => (v.aspectRatio || '') as string) || [];

  // Remove duplicates without using Set iteration
  const availableFormats = formats.filter(
    (format, index) => formats.indexOf(format) === index,
  );
  availableFormats.sort();
  return availableFormats;
};

// function that will give two significant figures and K for thousands and M for millions to numbers
export function formatAnalyticsNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
  }
  if (num >= 10000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
  }
  return num;
}

export const createImageElement = async (
  baseElement: HTMLElement,
  backgroundImage: string,
  quote: string,
  cb: () => void,
  aspectRatio = AspectRatio.AR_16_9,
) => {
  const placeholderContainer = document.createElement('div');
  const container = document.createElement('div');
  const imageContainer = document.createElement('div');

  placeholderContainer.id = 'placeholder-shareable-image';
  const computedStyles = getComputedStyle(baseElement);
  const heightTags = ['min-block-size', 'min-height', 'height'];
  const widthTags = ['max-inline-size', 'max-width', 'width'];

  const dimension = getShareableImageDimension(aspectRatio);
  const width = `${dimension.width / 2}px`;
  const height = `${dimension.height / 2}px`;

  for (let i = 0; i < computedStyles.length; i++) {
    const styleName = computedStyles[i];
    let value = computedStyles.getPropertyValue(styleName);
    // if (widthTags.includes(styleName)) value = width;
    if (heightTags.includes(styleName)) value = height;
    if (styleName.includes('color')) continue;
    if (styleName === 'background-image') {
      value = `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url("${backgroundImage}")`;
    }

    if (styleName === 'background-size') {
      value = 'cover';
    }

    if (styleName === 'background-position') {
      value = 'top';
    }

    if (styleName === 'background-repeat') {
      value = 'no-repeat';
    }

    if (styleName === 'background-color') {
      value = 'rgba(0, 0, 0, 0)';
    }
    imageContainer.style.setProperty(styleName, value);
  }

  imageContainer.style.borderRadius = '0px';
  imageContainer.style.aspectRatio = `${aspectRatio.split(':').join('/')}`;

  // placeholderContainer.style.width = width;
  // placeholderContainer.style.height = height;
  placeholderContainer.style.display = 'flex';
  placeholderContainer.style.justifyContent = 'center';
  placeholderContainer.style.alignItems = 'center';
  placeholderContainer.style.width = '666px';
  placeholderContainer.style.height = '500px';

  // container.style.width = width;
  // container.style.height = height;

  placeholderContainer.style.position = 'fixed';
  placeholderContainer.style.top = '50%';
  placeholderContainer.style.left = '50%';
  placeholderContainer.style.transform = 'translate(-50%, -50%)';

  document.body.appendChild(placeholderContainer);
  placeholderContainer.appendChild(container);
  container.appendChild(imageContainer);

  imageContainer.innerHTML = baseElement.innerHTML;

  const quoteElement = imageContainer.querySelector(
    '.quote-element',
  ) as HTMLElement;
  if (quoteElement) {
    quoteElement.innerHTML = quote;
    quoteElement.style.color = '#fff';
  }

  const svgElement = imageContainer.querySelector('svg');
  if (svgElement) {
    const pathElements = svgElement.querySelectorAll('path');
    pathElements.forEach((path) => {
      path.style.fill = '#fff';
    });
  }

  // Just logs
  const rect = container.getBoundingClientRect();
  console.log('rect', rect, '-- aspect ratio --', rect.width / rect.height);

  cb();
  const blobUrl = await toPng(container);
  placeholderContainer.remove();
  return blobUrl;
};

export const getShareableImageDimension = (currDimension: string) => {
  const width = 1060;
  const height = 1000;

  switch (currDimension) {
    case AspectRatio.AR_1_1:
      return { width, height };
    case AspectRatio.AR_9_16:
      return { width: (width * 9) / 16, height };
    case AspectRatio.AR_16_9:
      return { width: (width * 16) / 9, height };
    default:
      return { width, height };
  }
};

export const addUploadToStory = async (
  videoCreator: VideoCreatorStore,
  assetRepository: AssetRepository,
  newPhotoData: FileData & { fileName: string },
) => {
  const upload = await assetRepository?.uploadFile(newPhotoData);
  if (!upload) return newPhotoData.url!;
  const assetKey = newPhotoData.type === 'stock' ? 'storyAssets' : 'aiPhotos';
  const assets = [
    ...(videoCreator.story?.[assetKey] || []),
    {
      id: upload.id,
      title: newPhotoData.title,
      customData: {},
      format: upload.format,
      mimeType: upload.mime_type,
      url: upload.url,
      responsiveImage: {
        srcSet: upload.url,
        src: upload.url,
        alt: newPhotoData.alt,
        title: newPhotoData.title,
      },
      video: null,
      _createdAt: new Date(),
    },
  ] as Artifact[];

  videoCreator.story![assetKey] = assets;
  await videoCreator.updateStory(videoCreator.story!);
  return upload.url;
};

export const saveAssetToDato = async (
  videoCreator: VideoCreatorStore,
  assetRepository: AssetRepository,
  selectedImage: {
    url: string;
    type: 'stock' | 'ai' | 'artifact' | 'quotes';
    description?: string;
  },
) => {
  let url = selectedImage.url;
  const isInDato = selectedImage.url.startsWith(
    'https://www.datocms-assets.com',
  );

  if (!isInDato) {
    videoCreator.savingStockPhoto = true;
    const fileName = uuid();

    const newPhotoData: FileData & { fileName: string } = {
      type: selectedImage.type,
      url: selectedImage.url,
      fileName,
      alt: selectedImage.description || '',
      title: selectedImage.description || '',
    };
    const imageUrl = await addUploadToStory(
      videoCreator,
      assetRepository,
      newPhotoData,
    );
    url = imageUrl;
    videoCreator.savingStockPhoto = false;
  }
  return url;
};

export const getDocumentHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );

export const uploadFilesAndAsset = async (
  assetRepository: AssetRepository,
  files: (File & {
    preview?: string;
    id?: string;
    author?: string;
  })[],
  contributor: string,
  story: Story,
  cb: (
    file: File & { preview: string; id: string; description: string },
    upload: SimpleSchemaTypes.Upload,
  ) => void = () => {},
) => {
  const uploadResults = await Promise.all(
    files.map(async (file) => {
      const namesplit = file?.name?.split('.');
      const fileName = namesplit?.length
        ? namesplit[0]
        : window.crypto.randomUUID();
      const newFileData = {
        type: 'artifact' as 'artifact',
        file,
        fileName,
        author: file.author,
        alt: '',
        title: '',
        tags: contributor ? [contributor] : undefined,
        customData: { contributor },
      };
      const newUpload = await assetRepository?.uploadFile(newFileData);
      cb(
        file as File & { preview: string; id: string; description: string },
        newUpload!,
      );
      return newUpload;
    }),
  );

  const photoArtifactsIds = story?.storyArtifacts?.map((a) => a.id) || [];
  const videoArtifactsIds = story?.storyArtifactsVideo?.map((a) => a.id) || [];

  uploadResults.forEach((upload: any) => {
    if (upload) {
      if (upload.is_image) {
        photoArtifactsIds.push(upload.id);
      } else {
        videoArtifactsIds.push(upload.id);
      }
    }
  });
  console.log('videoArtifactsIds', videoArtifactsIds);

  story.storyArtifacts = photoArtifactsIds as unknown as Artifact[];
  story.storyArtifactsVideo = videoArtifactsIds as unknown as Artifact[];

  return story;
};

export const formatUploadToArtifact = (
  upload: SimpleSchemaTypes.Upload,
  data: { title?: string; alt?: string },
) => {
  return {
    id: upload.id,
    title: data.title || upload.default_field_metadata.en.title,
    customData: upload.default_field_metadata.en.custom_data,
    format: upload.format,
    mimeType: upload.mime_type,
    width: upload.width?.toString(),
    height: upload.height?.toString(),
    url: upload.url,
    ...(upload.is_image
      ? {
          responsiveImage: {
            srcSet: upload.url,
            src: upload.url,
            alt: data.alt || upload.default_field_metadata.en.alt,
            title: data.title || upload.default_field_metadata.en.title,
            base64: '',
            sizes: '',
          },
        }
      : null),
    video: null,
    _createdAt: new Date().toDateString(),
  } as Artifact;
};

export const FONTS = [
  'Abel',
  'Anton',
  'Archivo Black',
  'Arimo',
  'Barlow',
  'Bebas Neue',
  'Bitter',
  'Cabin',
  'Caveat',
  'Chakra Petch',
  'Cinzel',
  'Courgette',
  'Dancing Script',
  'DM Sans',
  'Dosis',
  'EB Garamond',
  'Fira Sans',
  'Great Vibes',
  'Hind Siliguri',
  'IBM Plex Sans',
  'Inconsolata',
  'Indie Flower',
  'Inter',
  'Josefin Sans',
  'Jost',
  'Kalam',
  'Kanit',
  'Karla',
  'Lato',
  'Libre Baskerville',
  'Libre Franklin',
  'Lobster',
  'Lora',
  'Manrope',
  'Merienda',
  'Merriweather',
  'Montserrat',
  'Mukta',
  'Mulish',
  'Nanum Gothic',
  'Neuton',
  'Noto Sans',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'Oswald',
  'Outfit',
  'Pacifico',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Proxima Nova',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Sacramento',
  'Satisfy',
  'Shadows Into Light',
  'Slabo',
  'Source Code Pro',
  'Space Grotesk',
  'Titillium Web',
  'Ubuntu',
  'Work Sans',
];

export const FONT_SIZE_VALUES = [
  12, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 48, 60, 72, 96,
];

export const checkWordsInString = (str: string, search: string) => {
  const lowercaseStr = str.toLowerCase();
  const wordsInSearch = (search.toLowerCase().match(/\w+/g) || []) as string[];
  // const wordsInStr = (lowercaseStr.match(/\w+/g) || []) as string[];

  for (let word of wordsInSearch) {
    if (!lowercaseStr.includes(word)) return false;
  }
  return true;
};

export const scrollIfNotInView = (
  dropdown: Element,
  container: Element,
  extraScroll = 100,
) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = dropdown.getBoundingClientRect();
  const intoView =
    container.scrollTop + elementRect.bottom - containerRect.bottom;

  if (elementRect.bottom > containerRect.bottom - extraScroll) {
    container.scrollTo({
      left: 0,
      top: intoView + extraScroll,
      behavior: 'smooth',
    });
  }
};

export const proximaNovaFonts = [
  {
    family: 'Proxima Nova',
    weight: 400,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563406-proximanova_light.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 500,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563409-proximanova_regular.ttf',
  },
  {
    family: 'Proxima Nova',
    weight: 600,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723562352-proximanova_bold.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 600,
    style: 'italic',
    source:
      'https://www.datocms-assets.com/99106/1723562359-proximanova_boldit.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 700,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563234-proximanova_extrabold.otf',
  },
];

/**
 * Ensures that all elements in a source have z_index values that match their track numbers
 * This helps maintain proper stacking order in the visual display
 *
 * @param source The source object containing elements array
 * @returns The source object with z_index values added to elements
 */
export function ensureElementZPositions(source: any): any {
  // This function name stays the same for backward compatibility, but it sets z_index values
  if (!source || !source.elements || !Array.isArray(source.elements)) {
    return source;
  }

  // Process each element in the source
  const updatedElements = source.elements.map((element: any) => {
    if (!element) return element;

    // Set z_index based on track if it doesn't have one
    let updatedElement = { ...element };

    // If element has a track number, set z_index to match
    if (updatedElement.track !== undefined) {
      // Special case for layout compositions
      if (updatedElement.type === 'composition' && updatedElement.track >= 90) {
        // Keep the z_index at -1000 for layout compositions
        updatedElement.z_index = -1000;
      } else {
        // Regular elements get z_index matching their track
        updatedElement.z_index = updatedElement.track;
      }
    }

    // Process nested elements recursively
    if (updatedElement.elements && Array.isArray(updatedElement.elements)) {
      updatedElement.elements = updatedElement.elements.map(
        (childElement: any) => {
          if (!childElement) return childElement;

          // Set z_index for child elements
          let updatedChild = { ...childElement };
          if (updatedChild.track !== undefined) {
            updatedChild.z_index = updatedChild.track;
          }

          return updatedChild;
        },
      );
    }

    return updatedElement;
  });

  return {
    ...source,
    elements: updatedElements,
  };
}

// Define an interface for the element data with layout properties
interface ElementExtraData {
  layoutPosition?: 'hidden' | 'top' | 'bottom';
  layoutAssociatedIds?: string[];
  layoutType?: string;
  [key: string]: any;
}

// Define the result type for the findLayoutRelatedElements function
interface LayoutElementsResult {
  hiddenParentId: string | null;
  layoutChildrenIds: string[];
  isHiddenParent: boolean;
  isLayoutChild: boolean;
  layoutType?: string;
}

/**
 * Finds layout-related elements based on an element ID
 *
 * @param store VideoCreatorStore instance
 * @param elementId ID of element to check
 * @returns Object containing hidden parent element ID and child layout element IDs
 */
export function findLayoutRelatedElements(
  store: any,
  elementId: string,
): LayoutElementsResult {
  const result: LayoutElementsResult = {
    hiddenParentId: null,
    layoutChildrenIds: [],
    isHiddenParent: false,
    isLayoutChild: false,
  };

  // Check if this element is a layout element
  const extraElementData: Record<string, ElementExtraData> =
    store.currentVideo?.extraElementData || {};
  const elementData: ElementExtraData | undefined = extraElementData[elementId];

  if (!elementData) return result;

  // Case 1: This is a hidden parent element
  if (
    elementData.layoutPosition === 'hidden' &&
    elementData.layoutAssociatedIds &&
    elementData.layoutAssociatedIds.length > 0
  ) {
    result.isHiddenParent = true;
    result.hiddenParentId = elementId;
    result.layoutChildrenIds = elementData.layoutAssociatedIds;
    result.layoutType = elementData.layoutType;
    return result;
  }

  // Case 2: This is a layout child (composition element)
  if (
    (elementData.layoutPosition === 'top' ||
      elementData.layoutPosition === 'bottom') &&
    elementData.layoutAssociatedIds &&
    elementData.layoutAssociatedIds.length > 0
  ) {
    result.isLayoutChild = true;
    result.hiddenParentId = elementData.layoutAssociatedIds[0];
    result.layoutType = elementData.layoutType;

    // Find all children of the same hidden parent
    for (const [id, data] of Object.entries(extraElementData)) {
      if (
        id !== elementId &&
        (data.layoutPosition === 'top' || data.layoutPosition === 'bottom') &&
        data.layoutAssociatedIds &&
        data.layoutAssociatedIds.length > 0 &&
        data.layoutAssociatedIds[0] === result.hiddenParentId
      ) {
        result.layoutChildrenIds.push(id);
      }
    }

    result.layoutChildrenIds.push(elementId);
    return result;
  }

  return result;
}

/**
 * Creates modifications to synchronize properties between hidden parent element and its layout children
 *
 * @param store VideoCreatorStore instance
 * @param modifications Existing modifications to enhance
 * @returns Enhanced modifications that include sync operations for layout elements
 */
export function syncLayoutElementModifications(
  store: any,
  modifications: Record<string, any>,
): Record<string, any> {
  if (!modifications || typeof modifications !== 'object') return modifications;

  // Clone the modifications to avoid mutating the original
  const syncedModifications = { ...modifications };

  // Properties that should be synchronized between hidden parent and layout children
  const syncProperties = [
    'time',
    'duration',
    'trim_start',
    'trim_end',
    'start',
    'end',
    'text',
  ];

  // Track which elements we've already processed to avoid infinite loops
  const processedElements: string[] = [];

  // Process each modification
  for (const key of Object.keys(modifications)) {
    // Parse the key to get elementId and property
    const [elementId, property] = key.split('.');
    if (!elementId || !property || processedElements.includes(elementId))
      continue;

    // Skip non-sync properties
    if (!syncProperties.includes(property)) continue;

    // Find related layout elements
    const layoutElements = findLayoutRelatedElements(store, elementId);

    // Add element to processed array
    processedElements.push(elementId);

    // Case 1: This is a hidden parent element - sync to children
    if (
      layoutElements.isHiddenParent &&
      layoutElements.layoutChildrenIds.length > 0
    ) {
      for (const childId of layoutElements.layoutChildrenIds) {
        if (!processedElements.includes(childId)) {
          syncedModifications[`${childId}.${property}`] = modifications[key];
          processedElements.push(childId);
        }
      }
    }

    // Case 2: This is a layout child - sync to parent and siblings
    else if (layoutElements.isLayoutChild && layoutElements.hiddenParentId) {
      // Sync to hidden parent
      if (!processedElements.includes(layoutElements.hiddenParentId!)) {
        syncedModifications[`${layoutElements.hiddenParentId}.${property}`] =
          modifications[key];
        processedElements.push(layoutElements.hiddenParentId!);
      }

      // Sync to sibling layout elements
      for (const siblingId of layoutElements.layoutChildrenIds) {
        if (siblingId !== elementId && !processedElements.includes(siblingId)) {
          syncedModifications[`${siblingId}.${property}`] = modifications[key];
          processedElements.push(siblingId);
        }
      }
    }
  }

  return syncedModifications;
}

// ElementExtraData is already defined above, no need to redefine it

/**
 * Handles layout elements when splitting or cutting an element.
 * This ensures that when a hidden parent element is split, all its layout children are also split,
 * and proper layout associations are maintained.
 *
 * @param store VideoCreatorStore instance
 * @param originalElementId ID of the element being split
 * @param newElementId ID of the new element created by the split
 * @returns Additional operations that need to be performed to maintain layout consistency
 */
export async function handleSplitLayoutElement(
  store: any,
  originalElementId: string,
  newElementId: string,
): Promise<void> {
  // Find layout elements related to the element being split
  const layoutElements = findLayoutRelatedElements(store, originalElementId);

  // If this isn't a layout element, do nothing
  if (!layoutElements.isHiddenParent && !layoutElements.isLayoutChild) {
    return;
  }

  // Case 1: Splitting a hidden parent element
  if (layoutElements.isHiddenParent) {
    // Create a new layout for the new element
    // Get element properties from the original
    const renderer = store.renderer;
    if (!renderer || !renderer.state) return;

    const originalSource = renderer.getSource();
    const originalElement = originalSource.elements.find(
      (el: any) => el.id === originalElementId,
    );
    if (!originalElement) return;

    // Create new layout composition elements for the new split element
    await store.addLayoutElements(
      newElementId,
      layoutElements.layoutType || 'stacked-1-1',
    );

    // Update extraElementData for new element
    const extraElementData: Record<string, ElementExtraData> =
      store.currentVideo.extraElementData || {};
    const newElementData = extraElementData[newElementId];
    if (newElementData) {
      newElementData.layoutType = layoutElements.layoutType || 'stacked-1-1';
    }

    return;
  }

  // Case 2: Splitting a layout child
  else if (layoutElements.isLayoutChild && layoutElements.hiddenParentId) {
    // Find out which parent is getting split
    const hiddenParentId = layoutElements.hiddenParentId;

    // Find the new hiddenParent that was created during the split
    const hiddenParentLayoutInfo = findLayoutRelatedElements(
      store,
      hiddenParentId,
    );

    // If the hidden parent wasn't split (which is unusual), just update references
    const extraElementData: Record<string, ElementExtraData> =
      store.currentVideo.extraElementData || {};
    const hiddenParentData = extraElementData[hiddenParentId];

    if (
      !hiddenParentLayoutInfo.layoutChildrenIds.length &&
      hiddenParentData &&
      hiddenParentData.layoutAssociatedIds
    ) {
      // Add the new element to the hidden parent's associated IDs
      hiddenParentData.layoutAssociatedIds.push(newElementId);

      // Create a new entry for the new element
      extraElementData[newElementId] = {
        ...extraElementData[originalElementId],
        layoutAssociatedIds: [hiddenParentId],
      };

      return;
    }

    // Normal case: The hidden parent was split too
    // In this case, we need to find the new hidden parent element
    // and make sure our new element is properly linked to it

    // Get all hidden parent elements
    const allHiddenParents: { id: string; extraData: ElementExtraData }[] = [];
    for (const [id, extraData] of Object.entries(extraElementData)) {
      if (
        extraData.layoutPosition === 'hidden' &&
        extraData.layoutType &&
        extraData.layoutAssociatedIds &&
        extraData.layoutAssociatedIds.length > 0
      ) {
        allHiddenParents.push({
          id,
          extraData,
        });
      }
    }

    // Find the hidden parent that doesn't have our original element in its associations
    // This is likely the newly created hidden parent from the split
    const newHiddenParent = allHiddenParents.find(
      (p) =>
        p.id !== hiddenParentId &&
        p.extraData.layoutAssociatedIds &&
        p.extraData.layoutAssociatedIds.every((id) => id !== originalElementId),
    );

    if (newHiddenParent && newHiddenParent.extraData.layoutAssociatedIds) {
      // Link our new element to this hidden parent
      store.currentVideo.extraElementData[newElementId] = {
        ...store.currentVideo.extraElementData[originalElementId],
        layoutAssociatedIds: [newHiddenParent.id],
      };

      // And add our new element to the hidden parent's associations
      newHiddenParent.extraData.layoutAssociatedIds.push(newElementId);
    }

    return;
  }
}
